import React, { Fragment, useState } from 'react';
import { Search, MyLocation } from '@mui/icons-material';
import { Button, ControlToolbar, GoogleMap, GridContainer } from '../../../../components';
import { Box, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../../assets';
import ProductCard from '../../../../components/Layouts/ProductCard';
import FilterDrawer from '../../../../components/Layouts/FilterDrawer';
import { Dialog } from '../../../../components/Dialog';
import { handleUseCurrentLocation, startLoader, stopLoader } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import ProductList from '../../../../components/Layouts/ProductList';
import { searchItems } from '../../../../services/home';
import { SET_SEARCH_PRODUCTS } from '../../../../redux';
import { textTransform } from '../../../../services/common';
import { CategoryData } from '../../../../model/CategoryData';
import StringFormatting from '../../../../utils/extensions/StringFormatting';
import CustomPagination from '../../../../components/Pagination';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Remove consoles

export const SearchMobile: React.FC = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [searchItem, setSearchItem] = useState('');

  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 20,
  });
  const [page, setPage] = useState(1);

  // Categories List
  const categories = useSelector((state: any) => [{
    _id: '',
    name: staticTexts.all
  }, ...state.category]);

  // Category Dropdown State
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Selected Category State
  const [selectedCategory, setSelectedCategory] = useState<CategoryData>(categories[0]);

  const dispatch = useDispatch();

  const [showSearchOptions, setShowSearchOptions] = useState(true);
  const [showProductListing, setShowProductListing] = useState(false);
  const [dateFilter, setDateFilter] = useState<any>(null);
  const [sortFilter, setSortFilter] = useState<any>(null);
  const [viewType, setViewType] = useState(staticTexts.grid);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    description: string;
  } | null>(null);
  const [state, setState] = useState({
    isDialogOpen: false,
  });
  const navigate = useNavigate();

  const closeDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: false,
    }));
  };

  const openDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: true,
    }));
  };

  const handleLocationSelect = (location: { lat: number; lng: number; description: string }) => {
    setSelectedLocation(location);
    closeDialog();
  };

  const handleSearch = async ({ dateFilter = {}, sortFilter = {}, viewMode = staticTexts.grid }: { dateFilter?: any; sortFilter?: any, viewMode: string }) => {
    try {
      // Show Loading
      startLoader();

      const params: any = {
        page: page,
        limit: meta.limit,
      };

      const response = (await searchItems({
        text: searchItem,
        ...(selectedCategory._id && { categoryId: selectedCategory._id }),
        ...(selectedLocation?.lat &&
          selectedLocation.lng && {
          location: {
            latitude: selectedLocation?.lat,
            longitude: selectedLocation?.lng,
          },
        }),
        ...(dateFilter && {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        }),
        ...(sortFilter && { sort: sortFilter.sortBy })
      },
        {
          params
        })) as any;
      setViewType(viewMode);

      // Dispatch API Response List
      dispatch({
        type: SET_SEARCH_PRODUCTS,
        payload: response.items ?? [],
      });
      setMeta({
        totalPages: response?.meta?.totalPages || 0,
        page: response?.meta?.page || 1,
        limit: response?.meta?.limit || 20,
      });
    } catch (error) {
      console.log(error);
    } finally {

      // Dismiss Loading
      stopLoader();
    }
    setShowSearchOptions(false);
    setShowProductListing(true);
  };

  const SearchItems = () => {
    const language = useSelector((state: any) => state.language);
    const staticTexts = getStaticTexts(language);
    const products = useSelector((state: any) => state.searchProducts);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const handleViewTypeChange = (value: string) => {
      setViewType(value);
    };

    const handleMobileFilter = (filters: any) => {
      const {
        viewMode,
        dateRange: { from: dateRangeFrom, to: dateRangeTo },
        sortBy,
        customDate: { from: customDateFrom, to: customDateTo },
      } = filters;

      setDateFilter({
        startDate: customDateFrom || dateRangeFrom,
        endDate: customDateTo || dateRangeTo,
      });

      setSortFilter({
        sortBy: sortBy.toUpperCase(),
      });

      handleSearch({
        dateFilter: {
          startDate: customDateFrom || dateRangeFrom,
          endDate: customDateTo || dateRangeTo,
        },
        sortFilter: {
          sortBy: sortBy.toUpperCase(),
        },
        viewMode,
      });
      setViewType(viewMode);
      toggleDrawer(false);
    };

    const toggleDrawer = (state: boolean) => {
      setOpen(state);
    };

    return (
      <Box className='w-fullflex flex-col'
        sx={{ paddingBottom: '16px' }}>
        <Box
          className='mx-auto'
          width={{
            xs: '92%',
            md: '80%',
          }}
          minHeight={{
            xs: '0px',
            md: '700px',
          }}
        >
          <ControlToolbar
            selectedTab={`${StringFormatting.capitalizeFirstLetterOfEveryWord(selectedCategory?.name)}`}
            viewType='Grid'
            onViewChange={handleViewTypeChange}
            itemCount={100}
            showButton={false}
            handleFilters={() => setOpen(true)}
            onSearch={(event: any) => console.log(event)}
          />

          <Box className='w-full'>
            {viewType.toUpperCase() === staticTexts.list.toUpperCase() ? (
              products.map((product: any, index: number) => (
                <div className='mb-2'>
                  <ProductList
                    key={index}
                    title={product.name}
                    description={product.description}
                    location={product.location?.name}
                    visibility={product.type}
                    date={product.date}
                    imageUrl={product.images[0]}
                    itemId={product._id}
                    showActions={false}
                    showClaimButton={true}
                    showMoreOptions={isSmallScreen}
                    onDelete={() => console.log('Delete clicked')}
                    menuOptions={[
                      {
                        label: staticTexts.claimItem,
                        onClick: () => navigate(`/item-detail/${product._id}`),
                      },
                    ]}
                  />
                </div>
              ))
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr 1fr",
                    sm: "1fr 1fr",
                    lg: "1fr 1fr 1fr",
                  },
                  gap: "16px",
                  width: "100%",
                }}>
                {products.map((product: any, index: number) => (
                  <ProductCard
                    key={index}
                    itemId={product._id}
                    image={product.image}
                    images={product.images}
                    itemCategory={product?._category?.name}
                    itemName={product.name}
                    description={product.description}
                    location={product.location.name}
                    date={product.date}
                    type={product.type}
                    showActions={false}
                    showPrivacy={false}
                    showClaimButton={true}
                  />
                ))}
              </Box>
            )}
          </Box>

          <FilterDrawer
            onApply={handleMobileFilter}
            isOpen={open}
            toggleDrawer={toggleDrawer}
          />
        </Box>

        <CustomPagination
          count={meta.totalPages}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    );
  };

   const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <Fragment>
      <div className='bg-[#ECE4D5]'>
        <div className={`w-full p-4 ${!showSearchOptions && 'bg-[#FFF7E6]'}`}>
          {/* Header */}
          {showSearchOptions && (
            <div className='flex items-center mb-6 text-accent'>
              <button
                className='text-coral-500 flex items-center'
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon className='w-5 h-5 mr-2' />
                <span>{staticTexts.back}</span>
              </button>
            </div>
          )}

          {/* Search Form */}

          <div className='flex justify-center items-center w-full flex-col text-sm'>
            {showSearchOptions && (
              <Typography
                fontSize='22px'
                marginBottom='12px'
                color='#4A403A'
                variant='body2'
                className='self-start'
              >
                {staticTexts.searchLostItems}
              </Typography>
            )}

            {/* Search Input */}
            <div className='relative w-full'>
              <input
                type='text'
                placeholder={staticTexts.searchLostItems}
                autoFocus
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
                onFocus={() => {
                  setShowSearchOptions(true);
                  setShowProductListing(false);
                }}
                className='w-full custom-placeholder p-3 rounded-lg bg-[#FFF7E6] focus:outline-none shadow-[0_0_12px_rgba(0,0,0,0.08)]'
              />
              {!showSearchOptions && (
                <button
                  onClick={() => setShowProductListing(true)}
                  className='absolute h-8 w-8 bg-[#FF6F61] rounded-full ltr:right-1 rtl:left-1 top-1/2 -translate-y-1/2 text-coral-500 flex justify-center items-center text-sm'
                >
                  <SearchIcon
                    className='w-4 h-4 text-[#FFF7E6]'
                    sx={{ fontSize: '18px' }}
                  />
                </button>
              )}
            </div>

            {showSearchOptions && (
              <Fragment>
                {/* Location Input */}
                <div className="relative my-2 w-full">
                  <input
                    type="text"
                    readOnly
                    placeholder="Search city, area or locality"
                    value={selectedLocation?.description}
                    disabled
                    className="w-full custom-placeholder p-3 pr-44 rounded-lg bg-[#FFF7E6] focus:outline-none"
                  />
                  <button className="absolute ltr:right-3 rtl:left-3 top-1/2 -translate-y-1/2 text-coral-500 flex items-center text-sm">
                    <span
                      className="text-accent me-2"
                      onClick={async () => {
                        const currentLocation = (await handleUseCurrentLocation()) as any;
                        if (currentLocation) {
                          setSelectedLocation({
                            lat: currentLocation?.latitude,
                            lng: currentLocation?.longitude,
                            description: currentLocation?.locationName,
                          });
                        }
                      }}
                    >
                      {staticTexts.useCurrentLocation}
                    </span>
                    <MyLocation
                      onClick={() => openDialog()}
                      className="w-4 h-4 ml-1 text-accent"
                      sx={{ fontSize: '16px' }}
                    />
                  </button>
                </div>


                {/* Category Dropdown */}
                <div className='relative w-full'>
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className='w-full p-3 rounded-lg bg-[#FFF7E6] text-[#776E65] text-left flex items-center justify-between'
                  >
                    <span>{textTransform(selectedCategory?.name)}</span>
                    <KeyboardArrowDownIcon
                      className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                    />
                  </button>

                  {isDropdownOpen && (
                    <ul className='absolute w-full mt-1 rounded-lg shadow-lg'>
                      {categories.map((category: any) => (
                        <li
                          key={category._id}
                          onClick={() => {
                            setSelectedCategory(category);
                            setIsDropdownOpen(false);
                          }}
                          className='w-full p-3 text-left hover:!bg-[#FFE0B5]'
                        >
                          {textTransform(category.name)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Search Button */}
                {!isDropdownOpen && (
                  <Button
                    buttonStyle={{
                      marginTop: '24px',
                    }}
                    onClick={() =>
                      handleSearch({
                        dateFilter,
                        sortFilter,
                        viewMode: staticTexts.grid,
                      })
                    }
                    className='p-3 rounded-lg self-end flex items-center justify-center space-x-2 hover:bg-coral-600 transition-colors'
                  >
                    <Search className='w-5 h-5' />
                    <span>Search</span>
                  </Button>
                )}
              </Fragment>
            )}

            <Dialog
              open={state.isDialogOpen}
              title=''
              content={<GoogleMap onLocationSelect={handleLocationSelect} />}
              onClose={closeDialog}
            />
          </div>
        </div>
      </div>

      {/*Item Listing*/}
      {showProductListing && <SearchItems />}
    </Fragment>
  );
};
