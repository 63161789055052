import { LocalStorageKeys } from '../../../constants/AppConstants';
import { SET_LANGUAGE } from '../../actions';

export const initialState = localStorage.getItem(LocalStorageKeys.LANGUAGE) || 'ar';

export const languageReducer = (state = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};
