import { RESET_PROFILE_DETAILS, SET_PROFILE_DETAILS, UPDATE_PROFILE_DETAILS } from '../../actions';

const initialState = {
  name: '',
  email: '',
  profile: '',
  phone: {
    prefix: '',
    number: '',
  },
  coverProfile: '',
  type: '',
};

/**
 * Handles profile state
 * @function
 * @param {object} state - Profile state
 * @param {object} action - Action containing type and payload
 * @returns {object} - New state
 */
export const profileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PROFILE_DETAILS:
      state = { ...action.payload };
      break;
    case RESET_PROFILE_DETAILS:
      state = initialState;
      break;
    case UPDATE_PROFILE_DETAILS:
      state = { ...state, ...action.payload };
  }
  return state;
};
