import styled from "@emotion/styled";

// export const GridContainer = styled.div`
//   display: grid;
//   gap: 1rem;
//   align-items: stretch;
//   grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));

//   @media (min-width: 768px) {
//     grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
//   }
// `;

export const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(140px, max-content));

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(180px, max-content));
  }
`;

