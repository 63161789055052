import StringValidations from "./StringValidations";

class StringFormatting {

    /**
     * @return Return string by replacing first letter to capital case
     *
     * @param str String
     * */
    static capitalizeFirstLetter(str: string): string {
        if (!str || !str.length) return str; // Handle empty string

        const firstLetter = str.charAt(0).toUpperCase();
        const restOfTheString = str.slice(1);
        return firstLetter + restOfTheString;
    }

    /**
     * @return Return string by replacing first letter to capital case and rest to lower case
     *
     * @param str String
     * */
    static capitalizeFirstLetterOnly(str: string): string {
        if (!str || !str.length) return str; // Handle empty string

        const firstLetter = str.charAt(0).toUpperCase();
        const restOfTheString = str.slice(1).toLowerCase();

        return firstLetter + restOfTheString;
    }

    /**
     * @return Return string by replacing first character of every word to capitalize
     *
     * @param str String
     * */
    static capitalizeFirstLetterOfEveryWord(str?: string | null): string | null {
        if (str == null) return null

        if (!str || !str.length) return str; // Handle empty string

        const words = str.split(" ");
        const capitalizedWords = words.map(word => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfTheWord = word.slice(1).toLowerCase();
            return firstLetter + restOfTheWord;
        });

        return capitalizedWords.join(" ");
    }

    /**
     * @return Email Address
     * */
    static getEmail(str?: string | null): string | null {
        if (!str || StringValidations.isBlank(str) || str.toUpperCase() == "na" || str.toUpperCase() == "no") {
            return null
        }

        return str.trim().toLowerCase()
    }

    /**
     * @return Return TRUE if value is not null or empty with trim
     *
     * @param value String
     * */
    static isNotNullAndEmptyTrim(value: string | null | undefined): boolean {
        return value !== null && value !== undefined && value.trim() !== "";
    }
}

export default StringFormatting;
