import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export const ContactSupport = () => {
  return (
    <div className='text-secondary-200 min-h-screen'>
      <Container
        maxWidth='lg'
        className='py-16'
      >
        {/* Header Section */}
        <div className='flex justify-center items-center'>
          <Typography
            variant='h4'
            className='font-semibold text-secondary-400'
          >
            Contact Our Support Team
          </Typography>
        </div>

        {/* Content Section */}
        <Box className='mt-8 space-y-8'>
          {/* Introduction */}
          <Box>
            <Typography className='text-lg leading-relaxed'>
              At Lqeet, we’re dedicated to helping you reconnect with your lost items or reunite someone with their
              belongings. If you’re having trouble using our platform or need assistance, our support team is here to
              help.
            </Typography>
          </Box>

          {/* How It Works */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              How Lqeet Works
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              <ul className='list-disc list-inside space-y-2'>
                <li>
                  <strong>Post Your Lost/Found Items:</strong> Create a post describing the lost or found item with
                  details like description, location, and date.
                </li>
                <li>
                  <strong>Browse Listings:</strong> Check the items posted by others to see if your lost item has been
                  found.
                </li>
                <li>
                  <strong>Claim Your Item:</strong> Click on the post, and start a chat with the person who listed the
                  item to verify and claim it.
                </li>
                <li>
                  <strong>Chat & Verify:</strong> Use our secure chat system to communicate and confirm ownership.
                </li>
              </ul>
            </Typography>
          </Box>

          {/* Contact Options */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Need Help? Reach Out to Us!
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              If you experience any issues with posting, claiming, or chatting, or if you have questions about how Lqeet
              works, we’re here to support you.
            </Typography>
            <Typography className='text-lg leading-relaxed mt-4'>
              <strong>Contact Options:</strong>
              <ul className='list-disc list-inside mt-2 space-y-2'>
                <li>
                <EmailIcon fontSize='small'/> <a href='mailto:support@lqeet.com' className='text-accent'>support@lqeet.com</a>
                </li>
                <li>
                <ChatBubbleIcon fontSize='small'/>  Live Chat: Available during business hours via our platform.
                </li>
                <li>
                  <LocalPhoneIcon fontSize='small'/> Phone Support: Call us at <strong>+1 (123) 456-7890</strong>
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
