import React, { Fragment, useEffect, useState } from 'react';
import ChatList from './chat-list';
import ChatWindow from './chat-window';
import { Box, Typography } from '@mui/material';
import { chatList, getMessages } from '../../services/chat';
import { useDispatch, useSelector } from 'react-redux';
import { FETCHING_CHATS, RESET_MESSAGES, SCROLL_TO_BOTTOM, SET_MESSAGES, UPDATE_CHAT_LIST } from '../../redux';
import { useLocation } from 'react-router-dom';
import { getStaticTexts } from '../../assets';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Remove unused imports
// TODO - Mayank: Define interfaces in model directory not in the component itself
// TODO - Mayank: Format the code

interface User {
  id: string;
  name: string;
  _item: any;
  receiverProfile?: string;
  lastMessage: string;
  status: 'Online' | 'Offline';
  date: string;
  _receiver: string;
}

const ChatScreen: React.FC = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const userList = useSelector((state: any) => state?.chatlist?.chats);
  const { state } = useLocation();
  const { isFirstTime = false, _item = null, _receiver = null, username = '', userProfile = '' } = state || {};
  const messages = useSelector((state: any) => state?.messages || []);
  const [page, setPage] = useState(1);
  const [chatPage, setChatPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [search, setSearch] = useState('');
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchChatList = async () => {
      try {
        const response = await chatList({ page, limit: 10, text: search });
        dispatch({ type: UPDATE_CHAT_LIST, payload: response });
      } catch (error) {
        console.error('Error fetching chat list:', error);
      }
    };

    fetchChatList();
  }, [search, dispatch, page]);

  useEffect(() => {
    const fetchData = async () => {
      if (isFirstTime && _item && _receiver) {
        dispatch({ type: RESET_MESSAGES });
        const messages = await fetchMessages(_item, _receiver, 1, 500);
        dispatch({ type: SET_MESSAGES, payload: messages });
      }
    };

    fetchData();
  }, [isFirstTime, _item, _receiver]);

  const handleSearch = (value: string) => setSearch(value);
  const handleShowMore = () => setPage((prev) => prev + 1);

  const fetchMessages = async (itemId: string, receiverId: string, page: number, limit = 10) => {
    try {
      dispatch({
        type: FETCHING_CHATS,
        payload: true
      })
      const response = (await getMessages({ itemId, receiverId, page, limit })) as any;
      const newMessages = response?.messages?.reverse();
      return newMessages;
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally{
      dispatch({
        type: FETCHING_CHATS,
        payload: false
      })
    }
  };

  const handleSelectUser = async (user: any) => {
    setChatPage(1);
    setHasMoreMessages(true);
    const { _receiver, _item } = user || {};
    setSelectedUser(user);
    dispatch({ type: RESET_MESSAGES });
    const messages = await fetchMessages(_item, _receiver, 1);
    dispatch({ type: SET_MESSAGES, payload: messages });
  };

  const loadMoreMessages = async () => {
    let previousMessages;
      setChatPage((prev) => prev + 1);
     previousMessages = await fetchMessages(String(selectedUser?._item?._id), String(selectedUser?._receiver), chatPage + 1);
      if (previousMessages?.length === 0) setHasMoreMessages(false);
    const fetchedMessages = previousMessages || [];
    dispatch({
      type: SET_MESSAGES,
      payload: [ ...fetchedMessages, ...messages],
    })
    dispatch({
      type:SCROLL_TO_BOTTOM,
      payload: false
    })
    return;
  };

  return (
    <Box
      className='h-screen block md:flex justify-center w-full'
      marginTop={{ xs: '0px', md: '32px' }}
      position='fixed'
    >
      <Box
        sx={{
          width: { xs: '100%', md: '75%' },
          height: { xs: '100%', md: '85%' },
        }}
        className={`block md:flex gap-2 ${!isFirstTime ? 'justify-between' : 'justify-center'} h-full md:h-4/5`}
      >
        {!isFirstTime ? (
          <Fragment>
            <Box
              sx={{ width: { xs: '100%', md: '35%' }, minWidth: { md: '300px' } }}
              className={`${
                selectedUser ? 'hidden md:block' : 'block'
              } rounded-lg bg-[#FFF7E6] h-full w-full md:w-1/3 border-r border-gray-200`}
            >
              <ChatList
                users={userList}
                onSelectUser={handleSelectUser}
                className='h-full'
                onSearchUser={handleSearch}
                onShowMore={handleShowMore}
              />
            </Box>

            <Box
              sx={{ width: { xs: '100%', md: '65%' }, height: { xs: '93%', md: '100%' } }}
              className={`${selectedUser ? 'block' : 'hidden md:block'} rounded-lg bg-[#FFF7E6]`}
            >
              {selectedUser ? (
                <ChatWindow
                  user={selectedUser}
                  userProfile={selectedUser?.receiverProfile}
                  onBack={() => setSelectedUser(null)}
                  className='h-full'
                  messages={messages}
                  hasMore={hasMoreMessages}
                  onLoadMore={loadMoreMessages}
                />
              ) : (
                <Box className='hidden md:flex items-center justify-center h-full'>
                  <Typography
                    component='p'
                    color='text.secondary'
                    className='text-gray-500'
                  >
                    {userList?.length > 0 ? staticTexts.selectAChatToStartMessaging : staticTexts.noChatsAvailable}
                  </Typography>
                </Box>
              )}
            </Box>
          </Fragment>
        ) : (
          <Box
            sx={{ width: '80%', height: { xs: '93%', md: '100%' } }}
            className={`${selectedUser ? 'block' : 'hidden md:block'} rounded-lg bg-[#FFF7E6]`}
          >
            <ChatWindow
              user={{ _item, _receiver }}
              username={username}
              userProfile={userProfile}
              onBack={() => setSelectedUser(null)}
              className='h-full'
              messages={messages}
              hasMore={hasMoreMessages}
              onLoadMore={loadMoreMessages}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChatScreen;
