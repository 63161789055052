import { httpRequest } from '../network';
import STORE from '../../redux/store/index';

/**
 * Searches for items based on provided data and parameters.
 * Determines the appropriate endpoint based on the user's login status.
 *
 * @param {object} data - The data to be sent in the request body.
 * @param {object} params - The parameters to be sent in the request query.
 * @returns {Promise<any>} A promise that resolves with the search results data.
 */

export const searchItems = (data: object, params: object) => {
  const {
    auth: { isLoggedIn },
  } = STORE.getState();
  const endpoint = isLoggedIn ? '/aggregation/item/search-item' : '/aggregation/public/item/search-item';

  return new Promise((resolve, reject) =>
    httpRequest('POST', endpoint, data, params)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );
};

/**
 * Retrieves an item by its ID.
 * Sends a GET request to the server to fetch the item details.
 *
 * @param {string} itemId - The unique identifier of the item to retrieve.
 * @returns {Promise<any>} A promise that resolves with the item data.
 */

export const getItemById = (itemId: string) =>
  new Promise((resolve, reject) =>
    httpRequest('GET', `/aggregation/public/item/${itemId}`)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );
