import React from 'react';
import { Box } from '@mui/material';
import { getStaticTexts } from '../../../assets';
import { useSelector } from 'react-redux';

const NoItems: React.FC<{ message?: string }> = ({ message }) => {
  const language = useSelector((state: any) => state?.language);
  const staticTexts = getStaticTexts(language);

  return (
    <Box
      sx={{
        height: '200px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {message || staticTexts.noItemsAvailable}
    </Box>
  );
};

export default NoItems;
