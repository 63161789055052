import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

// Type for image
interface ImageProps {
  src: string;
  alt: string;
}

interface ImageCarouselProps {
  images: ImageProps[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToIndex = (index: number) => {
    setCurrentIndex(index);
  };

  console.log('image',images)
  return (
    <div className='relative w-full md:px-0 bg-[#4a403a14] rounded-md mb-3'>
      {/* Main Image */}
      <Box
        className='w-full h-[250px] md:h-[365px] flex items-center justify-center bg-secondary-400 rounded-t-md'
        sx={{ position: 'relative' }}
      >
        <img
          src={images[currentIndex]?.src}
          alt={images[currentIndex]?.alt}
          className='object-contain w-full h-full'
        />
      </Box>

      {/* Navigation Arrows */}
      <IconButton
        onClick={goToPrev}
        sx={{ position: 'absolute', top: '40%', left: '2%', transform: 'translateY(-50%)' }}
        className='z-10'
      >
        <ArrowBackIos
          fontSize='large'
          sx={{ color: '#93897F', fontSize: '14px' }}
        />
      </IconButton>
      <IconButton
        onClick={goToNext}
        sx={{ position: 'absolute', top: '40%', right: '2%', transform: 'translateY(-50%)' }}
        className='z-10'
      >
        <ArrowForwardIos
          fontSize='large'
          sx={{ color: '#93897F', fontSize: '14px' }}
        />
      </IconButton>

      {/* Custom Pagination Images */}
      <div className='flex justify-center space-x-2 p-3'>
        {images.map((image, index) => (
          <Box
            key={index}
            onClick={() => goToIndex(index)}
            className={`cursor-pointer rounded-md overflow-hidden border h-14 w-14 ${
              currentIndex === index ? 'border-accent' : 'border-transparent'
            }`}
          >
            <img
              src={image.src}
              alt={image.alt}
              className='object-cover h-full w-14 rounded-md transition-all duration-200'
            />
          </Box>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
