import React, { useCallback, useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { getStaticTexts } from './assets';
import { getCategories } from './services/aggregation';
import { useSnackbar } from './components';
import { getUserProfile } from './services/common';
import { SET_CATEGORY_DETAILS, SET_NOTIFICATION_COUNT, SET_PROFILE_DETAILS } from './redux';
import { LocalStorageKeys } from "./constants/AppConstants";
import { getNotificationCount } from './services/notification';

/**
 * Author: Mayank
 * Date: 8 December 2024
 *
 * Summary:
 * This file defines the main `App` component for the React application.
 * It integrates routing, handles language-based directionality (RTL/LTR), and applies
 * global styling configurations.
 *
 * Description:
 * - Imports necessary dependencies, including React, routing configuration, Redux for state management,
 *   and Emotion for styling.
 * - Determines the text direction (`ltr` or `rtl`) based on the selected language from the Redux store.
 * - Utilizes Emotion's `CacheProvider` and `stylis-plugin-rtl` to create an RTL cache for styles
 *   when required.
 * - Dynamically updates the `dir` attribute of the HTML `<html>` element based on the language's direction.
 * - Conditionally wraps the application's routing logic in an Emotion `CacheProvider` if RTL styling is needed.
 * - Adds impersonation token handling logic to store the token in localStorage and optionally fetch customer details.
 */

const App = React.memo(() => {
  const language = useSelector((state: any) => state.language) || 'ar';
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const enqueueSnackbar = useSnackbar();

  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const rtlLanguages = ['ar', 'fa', 'he', 'ur'];

  const direction = useMemo(() => (rtlLanguages.includes(language) ? 'rtl' : 'ltr'), [language]);

  const cacheRtl = useMemo(
    () =>
      createCache({
        key: 'muirtl',
        stylisPlugins: [rtlPlugin],
      }),
    []
  );

  const fetchCategories = useCallback(async () => {
    try {
      const categories = (await getCategories({})) as any;

      const payload = categories?.categories || [{ _id: 'ALL', name: staticTexts.all }];

      dispatch({
        type: SET_CATEGORY_DETAILS,
        payload,
      });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    }
  }, [dispatch, enqueueSnackbar, staticTexts]);

  const fetchUserProfile = useCallback(async () => {
    if (isLoggedIn) {
      try {
        const user = (await getUserProfile()) as any;
        // Set user details in the redux store
        localStorage.setItem(LocalStorageKeys.LANGUAGE, user?.defaultLanguage);

        dispatch({
          type: SET_PROFILE_DETAILS,
          payload: {
            _id: user?._id,
            name: user?.name,
            email: user?.email,
            profile: user?.profile,
            phone: user?.phone,
            gender: user?.gender,
            dob: user?.dob,
            address: user?.address,
            description: user?.description,
            coverProfile: user?.coverProfile,
            type: user?.type,
            defaultLanguage: user?.defaultLanguage,
          },
        });
      } catch (error: any) {
        if (error.response?.status === 409) {
          localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
          localStorage.removeItem(LocalStorageKeys.USER_TYPE);
          localStorage.removeItem(LocalStorageKeys.IMPERSONATE_TOKEN);
          localStorage.removeItem(LocalStorageKeys.IMPERSONATE_USER_TYPE);
        }
        enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
      }
    }
  }, [isLoggedIn, dispatch, enqueueSnackbar, staticTexts]);

  const fetchNotificationsCount = async () => {
    if(isLoggedIn) {
      try {
        const response = await getNotificationCount();
        dispatch({
          type: SET_NOTIFICATION_COUNT,
          payload: response,
        })
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message || staticTexts.somethingWentWrong)
      }
    }
  }

  useEffect(() => {
    fetchUserProfile();
    fetchNotificationsCount()
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
  }, [direction]);

useEffect(() => {
    // Check if notifications are supported in this browser
    if (typeof Notification !== 'undefined' && Notification?.permission !== "granted") {
      Notification.requestPermission().catch(err => {
        console.log('Notification permission error:', err);
      });
    }
}, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const impersonateToken = urlParams.get('impersonateToken');
    const userType = urlParams.get(LocalStorageKeys.USER_TYPE) || '';
    if (impersonateToken) {
      localStorage.clear();
      localStorage.setItem(LocalStorageKeys.IMPERSONATE_TOKEN, impersonateToken);
      localStorage.setItem(LocalStorageKeys.IMPERSONATE_USER_TYPE, userType);
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, document.title, newUrl);
      window.location.reload();
    }
  }, []);

  return (
    <div className={`${direction}`}>
      {direction === 'rtl' ? (
        <CacheProvider value={cacheRtl}>
          <RouterProvider router={routes} />
        </CacheProvider>
      ) : (
        <RouterProvider router={routes} />
      )}
    </div>
  );
});

export default App;
