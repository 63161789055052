import { Snackbar, Typography } from '@mui/material';
import { maxWidth, styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';
import { Language } from '../../constants/AppConstants';

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: '#4A403A',
    color: '#FFF7E6',
    borderRadius: '8px',
    padding: '8px 10px',
    minWidth: '350px',
    maxWidth:'350px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiSnackbarContent-message': {
    padding: '0px',
    textAlign: 'start', // Ensures correct alignment for both LTR and RTL
    flex: 1
  },
  '& .MuiIconButton-root': {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.75rem',
  },
  '& .MuiIconButton-root:hover': {
    textDecoration: 'underline',
  },
}));

export const CustomizedSnackbar = ({
  open,
  message,
  description,
  onClose,
  autoHideDuration,
}: {
  open: boolean;
  message: string;
  description?: string;
  status: string;
  onClose: () => void;
  autoHideDuration?: number;
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  // Determine direction (LTR or RTL)
  const isRTL = language === Language.AR;

  return (
    <CustomSnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration || 3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      style={{ direction: isRTL ? 'rtl' : 'ltr' }}
      message={
        <div style={{ textAlign: isRTL ? 'right' : 'left' }}>
          <Typography variant="body1" style={{ fontSize: "14px", flex: 1 }}>{message}</Typography>
          {description && (<Typography variant="body1" style={{ fontSize: "14px", flex: 1, }}>{description}</Typography>)}
        </div>
      }
      action={
        <span
          className="pe-2 text-sm cursor-pointer opacity-70 hover:opacity-100"
          onClick={onClose}
          style={{ marginLeft: isRTL ? -22 : -6, marginRight: isRTL ? 20 : 0 }}
        >
          {staticTexts.close}
        </span>
      }
    />
  );
};
