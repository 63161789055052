import React, { useState } from 'react';
import { Box, List, ListItem, Typography, IconButton, Menu, MenuItem, TextField, Grid2 } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getStaticTexts, productPlaceHolder, ProfileIcon } from '../../../assets';
import { Button, Modal, SearchInput, useSnackbar } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { ItemWithUserAvatar } from '../../../components/ItemWithUserAvatar';
import NoItems from '../../../components/Layouts/NoItems';
import DateTimeFormatting from '../../../utils/extensions/DateTimeFormatting';
import { RESET_MESSAGES, SCROLL_TO_BOTTOM } from '../../../redux';
import { reportUser } from '../../../services/chat';
import CustomButton from '../../../components/Button/CustomButton';
import { startLoader, stopLoader } from '../../../utils';
import { Dialog } from '../../../components/Dialog';
import { textTransform } from '../../../services/common';
import {rejectClaimRequest } from '../../../services/aggregation';
import { acceptClaimRequest, getClaimDetailsById } from '../../../services/claim-item';
import ImageIcon from '@mui/icons-material/Image';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Remove unused imports
// TODO - Mayank: Define interfaces in model directory not in the component itself

interface User {
  id: string;
  name: string;
  lastMessage: string;
  item: any;
  status: 'Online' | 'Offline';
  date: string;
}

interface ChatListProps {
  users: User[];
  onSelectUser: (user: User) => void;
  className?: string;
  onSearchUser: (value: string) => void;
  onShowMore: () => void;
}

const ChatList: React.FC<ChatListProps> = ({ users, onSelectUser, className, onSearchUser, onShowMore }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const language = useSelector((state: any) => state.language);
  const metaDetails = useSelector((state: any) => state.chatlist.meta)
  const staticTexts = getStaticTexts(language);
  const [comment, setComment] = useState('');
  const [userId, setUserId] = useState('');
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openViewDetailsModal, setViewDetailsModal] = useState(false);
  const [itemDetail, setItemDetail] = useState<any>({});
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [userDetail, setUserDetail] = useState<any>(null);

  const enqueueSnackbar = useSnackbar();
  const handleClick = (event: React.MouseEvent<HTMLElement>, user: any) => {
    setAnchorEl(event.currentTarget);
    setUserDetail(user)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUserDetail({})
  };

  const handleReportChat = (id: string) => {
    setOpenReportModal(true);
    setUserId(id);
    handleClose();
  };

  const [selectedUserId, setSelectedUserId] = useState<{
    userId: string;
    itemId: string;
  }>({
    userId: '',
    itemId: '',
  });
  const handleSelectUser = (user: User) => {
    setSelectedUserId({
      userId: user.id,
      itemId: user.item?._id,
    });
    dispatch({
      type: RESET_MESSAGES,
    });
    dispatch({
      type: SCROLL_TO_BOTTOM,
      payload: true,
    })
    onSelectUser(user);
  };

  const reportChatUser = async () => {
    try {
      const response = (await reportUser({
        comment: comment,
        reportedUserId: userId,
      })) as any;
      setOpenReportModal(false);
      setComment('');
      enqueueSnackbar(response?.message);
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message || staticTexts.somethingWentWrong);
    }
  };

  const handleOpenItemDetails = async (itemId: string, claimerId: string, itemName: string) => {
    try {
      startLoader();
      const response = (await getClaimDetailsById(claimerId, itemId)) as any;
      setItemDetail({ ...response, itemName });
      setViewDetailsModal(true);
    } catch (error: any) {
      enqueueSnackbar(error?.message || staticTexts.somethingWentWrong);
    } finally {
      handleClose();
      stopLoader();
    }
  };

  const onAccept = async () => {
    try {
      startLoader();
      await acceptClaimRequest(itemDetail?._userClaimBy?._id, itemDetail?._item);
      enqueueSnackbar(staticTexts.claimRequestAccepted);
    } catch (error) {
      enqueueSnackbar((error as any).message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
      setOpenAcceptModal(false);
    }
  };

  const onReject = async() => {
    try {
      startLoader();
      await rejectClaimRequest(itemDetail?._userClaimBy?._id, itemDetail?._item);
      enqueueSnackbar(staticTexts.claimRejectedSuccessfully);
      setOpenRejectModal(false);
    } catch (error: any) {
      enqueueSnackbar(error.response || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
  }
};

  return (
    <div
      className={`${className} py-4 ps-4 border-r border-gray-200`}
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <Typography variant='h6'>{staticTexts.chats}</Typography>

      <SearchInput
        placeholderText={staticTexts.searchHere}
        onSearch={onSearchUser}
        className='mt-2 me-4 mb-2'
      />
      {users?.length > 0 ? (
        <List style={{ flex: 1, overflowY: 'auto' }}>
          {(users || []).map((user: any) => {
            const {
              receiverProfile,
              itemName,
              receiverName,
              lastMessage,
              _id,
              itemImages,
              lastMessageCreatedAt,
            } = user;
            const isSelected = selectedUserId === _id;

            return (
              <Box>
                <ListItem
                  key={_id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#ECE4D5',
                      cursor: 'pointer',
                    },
                    backgroundColor: isSelected ? '#FFE0B5' : 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    padding: '8px',
                    paddingBottom: '12px',
                  }}
                >
                  {/* Image Container */}
                  <ItemWithUserAvatar
                    imageSrc={itemImages[0] || productPlaceHolder}
                    avatarSrc={receiverProfile || ProfileIcon}
                  />
                  <Box
                    className='ms-4 w-full flex flex-col'
                    sx={{
                      fontSize: '12px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => handleSelectUser(user)}
                  >
                    {/* Name, Time Container */}
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      {/* User Name */}
                      <Typography
                        variant='body2'
                        color='#4A403A'
                        sx={{ fontSize: '14px', fontWeight: '500' }}
                        className='truncate max-w-full'
                      >
                        {receiverName || staticTexts.lqeetUser}
                      </Typography>

                      {/* Last Message Date */}
                      <Typography
                        variant='body2'
                        color='#B5B3B0'
                        sx={{ fontSize: '12px' }}
                        className='truncate max-w-full'
                      >
                        {DateTimeFormatting.getDateByFormatCustomUTC(
                          lastMessageCreatedAt ?? '',
                          true,
                          DateTimeFormatting.DateTimeFormat.DD_MMM_yyyy
                        )}
                      </Typography>
                    </Box>

                    {/* Item Type Text */}
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Typography
                        variant='body2'
                        color='#6F6D6B'
                        sx={{
                          fontSize: '12px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {itemName}
                      </Typography>
                    </Box>

                    {/* Message Text */}
                    <Typography
                      variant='body2'
                      color='#6F6D6B'
                      sx={{
                        fontSize: '12px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {typeof lastMessage === 'string' ? lastMessage : (
                        <div className='flex justify-start items-center gap-1'>
                          <ImageIcon sx={{ fontSize: '14px' }} />
                          {lastMessage?.text}
                        </div>
                      )}
                    </Typography>
                  </Box>

                  {/* Menu Icon */}
                  <IconButton
                    edge='end'
                    disableRipple
                    onClick={(event) => {
                      handleClick(event, user);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  {/* Menu Options */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    sx={{
                      boxShadow: 'none', 
                      '& .MuiPaper-root': {
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)'
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        minWidth: '200px',
                      }}
                      onClick={() => {
                        handleReportChat(userDetail?._receiver);
                      }}
                    >
                      {staticTexts.reportUser}
                    </MenuItem>
                    { userDetail?._item?._user !== userDetail?._receiver && (
                      <MenuItem
                        sx={{
                          minWidth: '200px',
                        }}
                        onClick={() => {
                          handleOpenItemDetails(userDetail?._item?._id, userDetail?._receiver, userDetail?._item?.name);
                        }}
                      >
                        {staticTexts.viewClaimDetails}
                      </MenuItem>
                    )}
                  </Menu>
                </ListItem>
                {/* Bottom Divider */}
                <Box
                  sx={{
                    backgroundColor: '#ECE4D5',
                    height: isSelected ? '' : '1px',
                    width: '100%',
                    marginLeft: '72px',
                  }}
                />
              </Box>
            );
          })}
        </List>
      ) : (
        <NoItems message={staticTexts.noChatsAvailable} />
      )}

      <Box sx={{ margin: 'auto', padding: '8px 0px 0px 0px' }}>
        <Button
          variant='contained'
          color='secondary'
          onClick={onShowMore}
          sx={{ display: `${metaDetails?.page > 1 ? 'block' : 'none'}`, maxWidth: '400px', color: '#4a403a' }}
        >
          {staticTexts.showMore}
        </Button>
      </Box>

      <Modal
        open={openReportModal}
        onClose={() => {
          setOpenReportModal(false);
        }}
        onConfirm={reportChatUser}
        confirmText={staticTexts.confirm}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '412px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
          padding: '24px',
        }}
        buttonContainerStyle={{ justifyContent: 'space-between' }}
        cancelButtonStyle={{
          color: '#FF6F61',
          paddingInlineStart: '0px',
          justifySelf: 'self-start',
        }}
      >
        <Box position='relative'>
          <Typography
            variant='body2'
            component='p'
            letterSpacing='0.01px'
            fontWeight={400}
            fontSize='22px'
            textAlign='left'
          >
            {staticTexts.reportUser}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              mt: '24px',
            }}
          >
            <TextField
              name='comment'
              label={'Comment'}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              required
              fullWidth
              multiline
              rows={4}
            />
          </Box>
        </Box>
      </Modal>

      {/* View Item Details */}
      <Dialog
        open={openViewDetailsModal}
        onClose={() => {
          setViewDetailsModal(false);
          setItemDetail({});
        }}
        title={staticTexts.claimItemDetails}
        maxWidth='lg'
        titlePadding='16px 24px'
        contentPadding='0px 24px'
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Grid2
              container
              spacing={2}
            >
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.itemName}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    {itemDetail?.itemName}
                  </Typography>
                </Box>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.fullName}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    {itemDetail?._userClaimBy?.name}
                  </Typography>
                </Box>
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.dateLost}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    {DateTimeFormatting.getDateByFormatCustomUTC(
                      itemDetail?.eventDate ?? '',
                      true,
                      DateTimeFormatting.DateTimeFormat.DD_MMM_yyyy
                    )}
                  </Typography>
                </Box>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.location}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    {textTransform(itemDetail?.location?.name)}
                  </Typography>
                </Box>
              </Grid2>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.description}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                >
                  {textTransform(itemDetail?.description)}
                </Typography>
              </Box>
            </Grid2>

            {/* Uploaded Images Section */}
            {itemDetail?.images?.length > 0 && (
              <Box mt={4}>
                <Typography
                  variant='h6'
                  fontSize='16px'
                  color='#4A403A'
                  gutterBottom
                >
                  {staticTexts.uploadImages}
                </Typography>
                <Box
                  display='flex'
                  gap='8px'
                  paddingBottom='24px'
                >
                  {(itemDetail?.images || []).map((image: any, index: number) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Item ${index + 1}`}
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '8px',
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            <Box
              display='flex'
              gap='8px'
              justifyContent='end'
              width='100%'
              paddingBottom='24px'
              marginTop='auto'
            >
              <CustomButton
                variant='outlined'
                textColor='#655B53FA'
                title={staticTexts.decline}
                onPress={() => {
                  setOpenRejectModal(true);
                  setViewDetailsModal(false);
                }}
              />

              <CustomButton
                title={staticTexts.accept}
                onPress={() => {
                  setViewDetailsModal(false);
                  setOpenAcceptModal(true);
                }}
              />
            </Box>
          </Box>
        }
      />

      {/* Accept Claim Request */}
      <Modal
        open={openAcceptModal}
        onClose={() => setOpenAcceptModal(false)}
        onConfirm={onAccept}
        confirmText={staticTexts.confirm}
        cancelText={staticTexts.close}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.areYouSureYouWantToResolveThisItem}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.yourItemWillBeResolved}
        </Typography>
      </Modal>

      {/* Decline Claim Request */}
      <Modal
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        onConfirm={onReject}
        confirmText={staticTexts.confirm}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <Typography variant='body1'>{staticTexts.areYouSureWantToRejectClaimRequest}</Typography>
      </Modal>
    </div>
  );
};

export default ChatList;
