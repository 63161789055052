import { Typography } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import { arriva, awakenings, connexxion, dolfinarium, efteling, gvb } from '../../../assets';

export const PartnerCarousel = () => {
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Array of image URLs
  const imageUrls = [arriva, efteling, connexxion, dolfinarium, arriva, efteling, connexxion, dolfinarium, arriva, gvb];

  return (
    <div className="w-4/5 mx-auto p-1 mt-5">
      <div className="h-10">
        <Slider {...settings}>
          {imageUrls.map((url, index) => (
            <div key={index} className="w-24 px-2 flex items-center justify-center">
              <img
                className="h-10 object-contain mx-auto"
                src={url}
                alt="partners"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>

  );
};

export default PartnerCarousel;