import React, { useMemo } from 'react';
import { Button, ButtonGroup, styled, useMediaQuery } from '@mui/material';
import { Check } from '@mui/icons-material';
import { getStaticTexts } from '../../assets';
import { useSelector } from 'react-redux';

const ToggleButtonGroup = styled(ButtonGroup)({
  borderRadius: '24px',
  overflow: 'hidden',
  boxShadow: 'none',
});

interface ToggleOption {
  key: ReturnType<typeof getStaticTexts>[keyof ReturnType<typeof getStaticTexts>];
  labelKey: keyof ReturnType<typeof getStaticTexts>;
  icon?: React.ReactNode;
}

interface CustomToggleProps {
  selected: string;
  options: ToggleOption[];
  handleToggle: (option: ReturnType<typeof getStaticTexts | any>[keyof ReturnType<typeof getStaticTexts | any>]) => void;
  width?: string;
}

const ToggleButton = styled(Button)<{ selected: boolean }>(({ selected }) => ({
  textTransform: 'none',
  padding: '10px 20px',
  color: selected ? '#5e503f' : '#7a7065',
  backgroundColor: selected ? '#f5e1c7' : '#fdf7e5',
  borderRadius: '24px',
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  '&:hover': {
    backgroundColor: selected ? '#f5e1c7' : '#fdf7e5',
  },
  border: '1px solid #93897F',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

/**
 * A customizable toggle button group component.
 *
 * @param {string} selected - The currently selected option key.
 * @param {ToggleOption[]} options - An array of toggle options each containing a key, labelKey, and optional icon.
 * @param {string} [width] - The optional width for each button.
 * @param {function} handleToggle - Callback function invoked when a toggle option is selected.
 *
 * @returns {ReactElement} A styled group of toggle buttons that allows selection among the provided options.
 */

export const CustomToggle: React.FC<CustomToggleProps> = ({ selected, options, width, handleToggle }) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const isMobile = useMediaQuery('(max-width: 950px)'); // Adjust breakpoint as needed

  const maxLabelLength = Math.max(...options.map(({ labelKey }) => staticTexts[labelKey]?.length || 0));
  const buttonWidth = isMobile ? maxLabelLength * 8 : `${maxLabelLength * 8 + 30}px`;

  return (
    <ToggleButtonGroup>
      {options.map(({ key, labelKey, icon }) => (
        <ToggleButton
          key={key}
          selected={selected === key}
          onClick={() => handleToggle(key)}
          startIcon={selected === key && !isMobile ? <Check /> : null}
          sx={{
            width: { xs: '80px', md: width || buttonWidth },
            paddingX: { xs: '0px', md: '4px' },
            paddingY: { xs: '4px', md: '8px' },
            fontSize: { xs: '10px', md: '14px' },
          }}
          disableRipple
        >
          {staticTexts[labelKey]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
