import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import {
  DateInput,
  PhoneNumberInput,
  PasswordInput,
  Input,
  UploadBox,
  Dropdown,
  useSnackbar,
  Button,
} from '../../../components';
import { defaultProfileImg, getStaticTexts } from '../../../assets';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendSignupOTP } from '../../../services';
import { startLoader, stopLoader } from '../../../utils';

interface FormFields {
  name: string;
  email: string;
  prefix: string;
  number: string;
  dob: string;
  gender: string;
  idProof: string;
  password: string;
  confirmPassword: string;
  profile?: string;
}

/**
 * Signup component.
 *
 * This component renders the signup form. It includes
 *   - Name
 *   - Email
 *   - Phone number
 *   - Date of birth
 *   - Gender
 *   - ID proof
 *   - Password
 *   - Confirm password
 *   - Profile picture upload
 *   - Terms and condition checkbox
 *   - Privacy policy checkbox
 *   - Submit button
 *
 * @returns {JSX.Element} - The signup form component.
 */
export const Signup = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [dialCode, setDialCode] = useState('+966');
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const [profileImage, setProfileImage] = useState('');
  const signupData = sessionStorage.getItem('signup-data');

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      name: '',
      email: '',
      prefix: '+966',
      number: '',
      dob: '',
      gender: '',
      idProof: '',
      password: '',
      confirmPassword: '',
      profile: defaultProfileImg,
    },
  });

  useEffect(() => {
    try {
      if (signupData) {
        const { name, email, phone, dob, idProof, gender, profile, password, confirmPassword } =
          JSON.parse(signupData) || {};

        setValue('name', name || '');
        setValue('email', email || '');
        setValue('prefix', phone?.prefix || '+966');
        setValue('number', phone?.number || '');
        setValue('dob', dob || '');
        setValue('gender', gender || '');
        setValue('idProof', idProof || '');
        setValue('password', password || '');
        setValue('confirmPassword', confirmPassword || '');
        setValue('profile', profile || defaultProfileImg);
      }
    } catch (error) {
      console.error('Error parsing signupData:', error);
    }
  }, [signupData, setValue, defaultProfileImg]);

  /**
   * Handle country code change for the phone number input.
   * @param {SelectChangeEvent<string>} dialCode - The selected dial code.
   */
  const handleCountryCodeChange = (dialCode: SelectChangeEvent<string>) => {
    setDialCode(String(dialCode));
    setValue('prefix', String(dialCode).replace('+', ''));
  };

  /**
   * Updates the profile image and form value with the first image URL from the uploaded file URLs.
   *
   * @param {string[]} fileUrls - An array of file URLs from the uploaded images.
   */

  const handleImageUpload = (fileUrls: string[]) => {
    if (fileUrls && fileUrls.length > 0) {
      const imageUrl = fileUrls[0];
      setProfileImage(imageUrl);
      setValue('profile', imageUrl);
    }
  };

  /**
   * Handle form submission.
   *
   * This function will be called when the form is submitted. It will
   *   - Show a loading indicator
   *   - Prepare the request payload
   *   - Make an API call to send OTP for signup
   *   - Show a success message
   *   - Save the signup data in session storage
   *   - Navigate to the Verify OTP page
   *
   * @param {FormFields} data - The form data.
   */
  const onSubmit = async (data: FormFields) => {
    try {
      // Show Loading
      startLoader();

      // Prepare Request Payload
      const { prefix, number } = data;

      // API Call: Signup
      await sendSignupOTP({
        phone: {
          prefix: prefix.replace('+', ''),
          number,
        },
      });
      enqueueSnackbar(staticTexts.otpSentToYourMobileNumberForVerification);
      sessionStorage.setItem(
        'signup-data',
        JSON.stringify({
          ...data,
          phone: {
            prefix: prefix.replace('+', ''),
            number,
          },
          ...(profileImage ? { profile: profileImage } : {}),
        })
      );

      // Navigate to Verify OTP
      navigate('/auth/verify-otp');
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };

  return (
    <Box
      className='flex justify-center items-center w-full'
      minWidth='331px'
    >
      <Box
        className='text-center text-secondary-400 font-medium'
        width={{
          xs: '95%',
          md: '35%',
        }}
        padding={{ xs: '24px', md: '40px' }}
      >
        <Typography
          variant='h5'
          letterSpacing='0.01px'
          fontWeight={500}
        >
          {staticTexts.signUpPageHeaderTextLine1}
        </Typography>
        <Typography
          variant='h5'
          letterSpacing='0.01px'
          fontWeight={500}
        >
          {staticTexts.signUpPageHeaderTextLine2}
        </Typography>
        <Typography
          variant='body1'
          marginTop='12px'
        >
          {staticTexts.findReportAndRecoverItemsEasily}
        </Typography>
        <Box
          className='flex justify-center items-center flex-col'
          marginBottom='32px'
        >
          <Typography
            className='text-sm'
            marginTop='40px'
          >
            {staticTexts.profilePicture}
          </Typography>
          <UploadBox
            defaultImage={getValues('profile') || profileImage || defaultProfileImg}
            onFileChange={handleImageUpload}
          />
          <Typography
            fontSize='12px'
            marginTop='10px'
          >
            {staticTexts.supportedImgFormats}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name='name'
            className='!mb-4'
            label={staticTexts.fullName}
            placeholder={staticTexts.enterYourFullName}
            control={control}
            error={errors.name}
            isRequired
            validationRules={{
              maxLength: {
                value: 20,
                message: staticTexts.fullNameCanNotExceedMoreThan20character,
              },
            }}
          />

          <Input
            name='email'
            className='!mb-4'
            label={staticTexts.emailAddressOptional}
            placeholder={staticTexts.enterYourEmail}
            control={control}
            error={errors.email}
            validationRules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email format',
              },
            }}
          />

          <PhoneNumberInput
            name='number'
            className='!mb-3'
            label={staticTexts.phoneNumber}
            helperText={errors.number?.message || staticTexts.youWillReceiveOTPforVerification}
            countryCode={dialCode}
            control={control}
            error={errors.number}
            handleCountryCodeChange={handleCountryCodeChange}
            validationRules={{
              required: `${staticTexts.phoneNumber} ${staticTexts.isRequired}`,
            }}
          />

          <DateInput
            name='dob'
            className='!mb-4 w-full'
            label={staticTexts.enterDOB}
            control={control}
            error={errors.dob}
            helperText={errors.dob?.message}
            validationRules={{
              required: staticTexts.dateOfBirthIsRequired,
              validate: (value) => {
                const dob = new Date(value);
                const today = new Date();
                let age = today.getFullYear() - dob.getFullYear();
                const monthDiff = today.getMonth() - dob.getMonth();
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
                  age--;
                }
                return age >= 18 || staticTexts.youMustBeAtLeast18YearsOld;
              },
            }}
          />

          <Dropdown
            name='gender'
            dropdownStyle={{ marginBottom: '16px' }}
            control={control}
            options={[staticTexts.male, staticTexts.female, staticTexts.others]}
            labelText={staticTexts.selectGender}
            isRequired={true}
            error={errors.gender}
          />

          <Input
            name='idProof'
            className='!mb-4'
            label={staticTexts.addGovId}
            placeholder='e.g., DL4567658997'
            control={control}
            error={errors.idProof}
            isRequired
          />

          <PasswordInput
            name='password'
            label='Password'
            className='!mb-4'
            control={control}
            error={errors.password}
            validationRules={{
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
            }}
          />

          <PasswordInput
            name='confirmPassword'
            label='Confirm Password'
            className='!mb-4'
            control={control}
            error={errors.confirmPassword}
            validationRules={{
              required: 'Confirm Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
              validate: (value: string) => value === getValues('password') || 'Passwords must match',
            }}
          />

          <Typography
            variant='body1'
            textAlign='left'
            marginTop='24px'
            fontSize='12px'
          >
            {staticTexts.iAgreeTo}{' '}
            <span
              className='underline cursor-pointer'
              onClick={() => navigate('/terms-and-condition')}
            >
              {staticTexts.termsAndCondition}
            </span>{' '}
            {staticTexts.and}{' '}
            <span
              className='underline cursor-pointer'
              onClick={() => navigate('/privacy-policy')}
            >
              {staticTexts.privacyPolicy}
            </span>
          </Typography>
          <Button
            type='submit'
            buttonStyle={{
              marginTop: '40px',
            }}
            className='px-6 py-2 rounded-lg w-fit text-center text-sm'
          >
            {staticTexts.createAccount}
          </Button>
        </form>
        <Typography
          variant='body1'
          fontSize='12px'
          color='#776E65'
          marginTop='40px'
          className='text-center cursor-pointer'
        >
          {staticTexts.needHelp}{' '}
          <span
            className='underline'
            onClick={() => navigate('/contact-support')}
          >
            {staticTexts.contactSupport}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
