import React, { Fragment } from 'react';
import { ContentBanner } from '../../components';
import { Outlet } from 'react-router-dom';

export const HomePage: React.FC = () => {
  
  return (
    <Fragment>
      <ContentBanner />
      <Outlet />
    </Fragment>
  );
};
