import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../assets';
import { Input, useSnackbar } from '../../../components';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';
import { useForm } from 'react-hook-form';
import { resetPassword } from '../../../services';
import { Box, Typography } from '@mui/material';
import { startLoader, stopLoader } from '../../../utils';
import { UserType } from '../../../constants/AppConstants';


  /**
   * Forgot Password page for business users.
   *
   * This page allows the user to enter their email address and receive a reset password link.
   * The link is sent by email and is valid for 30 minutes.
   * The user can request a new link if they don't receive the email.
   * The page is protected by a loader while the API call is in progress.
   * If the API call fails, an error message is displayed.
   * If the API call succeeds, the user is redirected to the login page.
   * @returns {ReactElement} The Forgot Password page for business users.
   */
const BusinessForgotPassword = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), []);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const enqueueSnackbar = useSnackbar();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: ['', '', '', ''],
      email: '',
    },
  });

  const [timer, setTimer] = useState<number>(0);


  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [timer]);

  /**
   * Form Submit Click
   */
  const onSubmit = async (data: { email: string }) => {
    try {
      if (!data.email) {
        setError('email', {
          type: 'manual',
          message: `${staticTexts.email} ${staticTexts.isRequired}`,
        });
        return;
      }

      // Show Loading
      startLoader();

      // API Call: Send Reset Password Link
      await resetPassword({
        email: data.email,
        type: UserType.BUSINESS,
      });

      // Start Timer
      setTimer(30);

      // Show Message
      enqueueSnackbar(staticTexts.resetLinkSentSuccessfully);

      // Email sent successfully
      setIsEmailSent(true);

      // Set Timer
      setTimer(30);

    } catch (error) {
      // Show Message
      enqueueSnackbar((error as any)?.response?.data?.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };


  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <Typography
            component='p'
            fontSize='24px'
            variant='body2'
            className='font-medium'
          >
            {staticTexts.forgotPassword}
          </Typography>
          <Typography
            component='p'
            fontSize='14px'
            className='text-secondary-200 mt-2'
          >
            {staticTexts.enterEmailAddressToReceiveResetLink}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className='mt-6'>
            <Box className='flex items-center rounded-md py-2'>
              <Input
                name='email'
                label={staticTexts.email}
                control={control}
                error={errors.email}
                isRequired
              />
            </Box>

            {isEmailSent && (<Typography
              component='p'
              fontSize='12px'
              marginTop='2px'
              marginBottom='24px'
              justifyContent='flex-start'
              textAlign='left'
              color='#776E65'
              marginInlineStart='16px'
            >
              {staticTexts.didNotReceiveTheLink}{' '}
              {timer > 0 ?
                `${staticTexts.requestAnewOne} ${timer} ${staticTexts.seconds}` :
                <span className='text-accent underline cursor-pointer' onClick={handleSubmit(onSubmit)}>{staticTexts.resendOTP}</span>
              }
            </Typography>)}

            <Typography
              component='p'
              fontSize='12px'
              textAlign='left'
              className='text-secondary-200 mt-2'
            >
              {staticTexts.weWillSendResetLink}
            </Typography>
          </Box>

          <Box className='flex gap-4 mt-8 justify-center items-center'>
            <Button
              variant='outlined'
              className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
              onClick={() => navigate(-1)}
              buttonStyle={{
                color: '#111111D8',
                border: '1px solid #C9C2B4',
              }}
            >
              {staticTexts.backToLogin}
            </Button>

            <Button
              type='submit'
              className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
            >
              {staticTexts.continue}
            </Button>
          </Box>
        </form>

        <Typography
          variant='body1'
          fontSize='12px'
          color='#776E65'
          marginTop='40px'
          className='text-center cursor-pointer'
        >
          {staticTexts.needHelp}{' '}
          <span
            className='underline'
            onClick={() => navigate('/contact-support')}
          >
            {staticTexts.contactSupport}
          </span>
        </Typography>
      </Box>
    </Fragment>
  );
};

export default BusinessForgotPassword;
