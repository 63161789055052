import { httpRequest } from "../network";

/**
 * Gets the count of unread notifications for the current user.
 *
 * @returns {Promise<number>} a promise that resolves with the count of unread notifications.
 */
export const getNotificationCount = async () => new Promise(async (resolve, reject) => {

    try {
        const response = await httpRequest("GET", "/notification/v1/notification/count") as any;
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

/**
 * Retrieves a list of notifications for the current user.
 * 
 * @param {Object} params - an object containing query parameters or additional data for the request.
 * @returns {Promise<any>} a promise that resolves with the list of notifications.
 */

export const getNotifications = async (params: any) => new Promise(async (resolve, reject) => {
    try {
        const response = await httpRequest("GET", "/notification/v1/notification", {}, params) as any;
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

/**
 * Deletes all the notifications for the current user.
 *
 * @returns {Promise<any>} a promise that resolves with the response of the delete notifications request.
 */
export const deleteNotifications = async () => new Promise(async (resolve, reject) => {
    try {
        const response = await httpRequest("DELETE", `/notification/v1/notification`) as any;
        resolve(response?.data);
    } catch (error) {
        reject(error);
    }
});

  /**
   * Displays a desktop notification with the given title, body and icon.
   * Only displays the notification if the user has granted permission.
   * @param {string} title - the title of the notification
   * @param {string} body - the body of the notification
   * @param {string} icon - the icon of the notification
   */
export const showNotification = (title: string, body: string, icon: string) => {
    if (Notification.permission === "granted") {
      const notification = new Notification(title, { body, icon });
      notification.onclick = () => {
        window.focus();
      };
    } else {
      console.log("Notification permission not granted.");
    }
  };
  