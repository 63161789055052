import React, { Fragment } from 'react';
import { Avatar, Box } from '@mui/material';

interface CardProps {
  imageSrc: string;
  avatarSrc: string;
}

export const ItemWithUserAvatar: React.FC<CardProps> = ({ imageSrc, avatarSrc }) => {
  return (
    <Fragment>
      <div className='flex relative'>
        {/* Image Box */}
        <Box className='rounded-lg h-12 w-12'>
          <img
            src={imageSrc}
            alt='item-image'
            className='rounded-md w-full h-full object-cover'
          />
        </Box>

        {/* Avatar Overlay */}
        <Avatar
          src={avatarSrc}
          alt='Avatar'
          className='absolute ltr:right-4 rtl:left-4 border-2 border-white'
          sx={{
            position: 'absolute',
            bottom: '-6px',
            right: '-8px',
            height: '28px',
            width: '28px',
          }}
        />
      </div>
    </Fragment>
  );
};
