import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTheme } from "@mui/material";

// Main Avatar Container (Parent Positioning)
const AvatarContainer = styled("div")({
  position: "relative",
  display: "inline-block",
});

export const BadgeAvatars: FC<{ senderImage: string }> = ({ senderImage }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <AvatarContainer>
        <Avatar
          alt="Main Avatar"
          src={senderImage}
          sx={{
            width: 40,
            height: 40,
            border: `0.5px solid ${theme.palette.background.paper}`,
            backgroundColor: senderImage
              ? theme.palette.background.paper
              : theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {!senderImage && (
            <AccountCircleIcon
              sx={{
                width: "80%",
                height: "80%",
                color: theme.palette.primary.contrastText,
              }}
            />
          )}
        </Avatar>
      </AvatarContainer>
    </Stack>
  );
};
