export enum AppButtonVariant {
  CONTAINED = 'contained',
  TEXT = 'text',
  OUTLINED = 'outlined',
};

export enum UserType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
};

export enum LocalStorageKeys {
  LANGUAGE = 'language',
  AUTH_TOKEN = 'auth-token',
  IMPERSONATE_TOKEN = 'impersonate-token',
  IMPERSONATE_USER_TYPE = 'impersonate-user-type',
  USER_TYPE = 'user-type',
  OTP_TIMER_END = "otpTimerEnd",
};

export enum SessionStorageKeys {
  EMAIL = 'email',
};

export enum Language {
  AR = 'ar',
  EN = 'en',
};
