import { SET_CHAT_LIST, UPDATE_CHAT_LIST, UPDATE_LAST_MESSAGE } from '../../actions';

const initialState: any = JSON.parse(localStorage.getItem('chat-list') || '{}');

/**
 * Handles chat list state
 * @function
 * @param {object} state - Chat list state
 * @param {object} action - Action containing type and payload
 * @returns {object} - New state
 */
export const chatListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CHAT_LIST:
      return action.payload;
    case UPDATE_CHAT_LIST:
      return { ...state, ...action.payload };
    case UPDATE_LAST_MESSAGE:
      const { receiverId, itemId, message } = action.payload;
       
      // Find the index of the existing item based on receiverId and itemId
      const existingIndex = (state?.chats || []).findIndex(
        (item: any) => item?._receiver === receiverId && item?._item?._id === itemId
      );
      
     if (existingIndex !== -1) {
      //  Update the last message of the existing item
        const updatedChats = [...(state.chats || [])];
        updatedChats[existingIndex] = {
          ...updatedChats[existingIndex], // Clone the existing item
          lastMessage: message,
        };

        // Return the updated state with updated chats
        return {
          ...state,
          chats: updatedChats,
        };
      }
      
      return state;
    default:
      return state;
  }
};
