import { UPDATE_LOADER } from '../../actions';

const initialState = false;

export const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOADER:
      return action.payload;
    default:
      return state;
  }
};
