import React from 'react';
import { cookieIcon, getStaticTexts } from '../../../assets';
import { Typography } from '@mui/material';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';

interface CookieConsentProps {
  onAccept: () => void;
  onIgnore: () => void;
}

export const CookieConsent: React.FC<CookieConsentProps> = ({ onAccept, onIgnore }) => {
  const language = useSelector((state: any)=>state.language);
  const staticTexts = getStaticTexts(language);
  return (
    <div className="fixed bottom-4 left-4 right-4 md:left-1/2 md:-translate-x-1/2 rtl:md:-translate-x-1/4 md:w-4/5 md:max-w-[887px] bg-[#fdf6e9] rounded-2xl shadow-lg p-4 animate-slide-up z-50">
    <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
      <div className="hidden md:flex-shrink-0 sm:block">
        <img src={cookieIcon} alt="cookie-icon" />
      </div>
  
      <div className="flex-grow text-sm">
        <Typography
          fontSize="14px"
          variant="body1"
          color="#4A403A"
        >
        {staticTexts.cookieConsentText}
        </Typography>
      </div>
  
      <div className="flex gap-2 flex-row md:flex-row md:flex-shrink-0 w-full md:w-auto mt-4 md:mt-0">
        <Button
          variant="outlined"
          buttonStyle={{
            border: "1px solid #C9C2B4",
            color: "#4A403A",
          }}
          onClick={onIgnore}
        >
         {staticTexts.ignore}
        </Button>
        <Button onClick={onAccept}>{staticTexts.allow}</Button>
      </div>
    </div>
  </div>
  
  );
};
