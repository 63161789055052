import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';

export const Container = styled(Box)`
  background-color: #fff7e6;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  min-width: 24rem;
  margin-top:auto;
`;

export const Content = styled(Box)`
  width: 100%;
  max-width: 1200px;
`;

export const FooterWrapper = styled(Box)`
  padding: 1.5rem;
`;

export const Logo = styled('img')`
  margin-bottom: 1.5rem;
`;

export const SectionTitle = styled(Typography)`
  font-weight: 500;
  margin-bottom: 1.75rem;
  font-size: 14px;
`;

export const LinkList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;

  .linkItem {
    margin-bottom: 1.25rem;
  }
`;
