import { httpRequest } from '../network';

/**
 * Retrieves a list of chat users.
 * @param {Object} payload - an object containing query parameters or additional data for the request.
 * @returns {Promise} - a promise that resolves with the list of chat users.
 */

export const chatList = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('GET', '/chat/user/search-users', {}, payload)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );

/**
 * Retrieves a list of messages for a given user.
 * @param {Object} payload - an object containing query parameters or additional data for the request.
 * @returns {Promise} - a promise that resolves with the list of messages.
 */
export const getMessages = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('GET', '/chat/message/messages', {}, payload)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );

/**
 * Reports a user with the given payload.
 * @param {Object} payload - an object containing details necessary to report a user.
 * @returns {Promise} - a promise that resolves once the report has been submitted.
 */

export const reportUser = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/report', payload)
      .then(resolve)
      .catch(reject));
