import React, { useState, useEffect, Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';
import { Typography } from '@mui/material';
import { LocalStorageKeys } from '../../constants/AppConstants';

const OTPIputComponent: React.FC<{
  otp: string[];
  onChange: (index: number, value: string) => void;
  error?: string;
  onResendOTP:() => void;
}> = ({ otp, onChange, error, onResendOTP }) => {
  const [timer, setTimer] = useState(() => {
    const initialTime = 30;
    localStorage.setItem(LocalStorageKeys.OTP_TIMER_END, (Date.now() + initialTime * 1000).toString());
    return initialTime;
  });

  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const isRTL = language === 'ar';

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (timer > 0) {
      const endTime = Date.now() + timer * 1000;
      localStorage.setItem('otpTimerEnd', endTime.toString());

      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            localStorage.removeItem('otpTimerEnd');
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [timer]);

  const handleChange = (value: string, index: number) => {
    if (/^[0-9]$/.test(value) || value === '') {
      onChange(index, value);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`)?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      onChange(index - 1, '');
      document.getElementById(`otp-${index - 1}`)?.focus();
    }
  };

  return (
    <Fragment>
      <div className='flex justify-center space-x-2 sm:space-x-3 relative'>
        <label
          htmlFor={`otp-0`}
          className={`w-fit absolute -top-2 ${
            isRTL ? 'right-10 md:right-2' : 'left-16 md:left-16 lg:left-1'
          }  bg-[#ECE4D5] px-1 text-gray-600 ${error && '!text-error'} text-xs transition-all`}
        >
          OTP
        </label>
        {otp.map((value, index) => (
          <Fragment key={index}>
            <input
              id={`otp-${index}`}
              placeholder='0'
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className={`
              otp-input
              text-center
              ${index === 0 && '!ms-0'}
              text-lg sm:text-xl 
              w-10 sm:w-12 md:w-14 lg:w-24
              h-10 sm:h-12 
              bg-[#ECE4D5] 
              border border-gray-800
              ${error && '!border-error'}
              focus:outline-none 
              rounded 
              custom-placeholder
            `}
            />
          </Fragment>
        ))}
      </div>
      {error && (
        <p className='text-error flex justify-center md:justify-start md:text-left text-center text-xs w-full mt-1 md:ms-4'>
          {error}
        </p>
      )}

<Typography
  component='p'
  fontSize='12px'
  marginTop='4px'
  justifyContent='flex-start'
  textAlign='left'
  color='#776E65'
  marginInlineStart='16px'
>
  {staticTexts.didNotReceiveACode}{' '}
  {timer > 0 ? 
    `${staticTexts.requestAnewOne} ${timer} ${staticTexts.seconds}` : 
    <span className='text-accent underline cursor-pointer' onClick={()=>{
      setTimer(30);
      onResendOTP();
    }}>{staticTexts.resendOTP}</span>
  }
</Typography>
    </Fragment>
  );
};

export const OTPInput = memo(OTPIputComponent);
