import { NEW_MESSAGE, RESET_MESSAGES, SET_MESSAGES } from '../../actions';

export const initialState: any = localStorage.getItem('messages') || [];

/**
 * Handles messages state
 * @function
 * @param {array} state - Messages state
 * @param {object} action - Action containing type and payload
 * @returns {array} - New state
 */
export const messagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MESSAGES:
      return action.payload;

    case NEW_MESSAGE:
      return [...state, action.payload];

    case RESET_MESSAGES:
      return []

    default:
      return state;
  }
};
