import { httpFormRequest, httpRequest } from '../network';

/**
 * Logs in the user.
 * @param {Object} payload - an object containing login credentials.
 * @returns {Promise} - a promise that resolves with the response of the login request.
 */
export const login = (payload: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const login = (await httpRequest('POST', '/user/public/login', payload)) as any;
      resolve(login?.data);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Retrieves the user profile of the logged in user.
 * @returns {Promise<Object>} - a promise that resolves with the user profile.
 */
export const getUserProfile = async () =>
  new Promise(async (resolve, reject) => {
    try {
      const profile = (await httpRequest('GET', '/user/customer/profile')) as any;
      resolve(profile?.data);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Updates the profile of the logged in user.
 * @param {Object} payload - an object containing updated profile information.
 * @returns {Promise<Object>} - a promise that resolves with the updated user profile.
 */
export const updateProfile = (payload: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const profile = (await httpRequest('PUT', '/user/customer/profile', payload)) as any;
      resolve(profile?.data);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Changes the password of the logged in user.
 * @param {Object} payload - an object containing the old password, new password and confirm new password.
 * @returns {Promise<Object>} - a promise that resolves with the response of the change password request.
 */
export const changePassword = (payload: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const change = (await httpRequest('POST', '/user/auth/change-password', payload)) as any;
      resolve(change?.data);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Uploads a file to the server.
 * @param {Object} file - an object representing the file to be uploaded.
 * @returns {Promise<Object>} - a promise that resolves with the server's response data.
 */

export const upload = (file: any = {}) => {
  return httpFormRequest('/upload/v1/media/upload', file)
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Logs out the user.
 * @returns {Promise<Object>} - a promise that resolves with the server's response data.
 */
export const logout = () =>
  new Promise(async (resolve, reject) => {
    try {
      const logout = (await httpRequest('POST', '/user/auth/logout')) as any;
      resolve(logout?.data);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Resets the password for a user.
 * @param {Object} payload - an object containing the user's email.
 * @returns {Promise<Object>} - a promise that resolves with the server's response data for the password reset request.
 */

export const resetPassword = async (payload: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const reset = (await httpRequest('POST', '/user/public/reset-password', { email: payload.email })) as any;
      resolve(reset?.data);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Truncates the text to a specified maximum length and appends '...' if the text exceeds that length.
 * 
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the returned text.
 * @returns {string} - The truncated text with '...' appended if it exceeds maxLength, otherwise the original text.
 */

export const handleCharacterLimit = (text: string, maxLength: number) => {
  if (text?.length > maxLength) {
    return text?.substring(0, maxLength) + '...';
  }
  return text;
};

export const textTransform = (data: any) => data?.charAt(0)?.toUpperCase() + data?.slice(1)?.toLowerCase();
