import React, { Fragment, useEffect, useState } from 'react';
import { OTPInput } from '../../../../components/OTPInput';
import { Box, Typography } from '@mui/material';
import { Button, useSnackbar } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticTexts } from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { login, verifyLoginOTP } from '../../../../services';
import { startLoader, stopLoader } from '../../../../utils';
import { SET_PROFILE_DETAILS, UPDATE_LOGIN_STATUS } from '../../../../redux';
import { claimItem } from '../../../../services/claim-item';
import { createItem } from '../../../../services/aggregation';
import { LocalStorageKeys, UserType } from '../../../../constants/AppConstants';

/**
 * @function LoginWithOTP
 * @description Component for login with OTP
 * @param {object} language - Language object
 * @param {object} staticTexts - Static texts object
 * @param {function} navigate - Navigate function
 * @param {function} dispatch - Dispatch function
 * @param {function} enqueueSnackbar - Enqueue snackbar function
 * @returns {ReactElement} Login with OTP component
 */
export const LoginWithOTP = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: ['', '', '', ''],
    },
  });

  const enqueueSnackbar = useSnackbar();

/**
 * Handles the submission of the OTP form.
 *
 * This function validates the provided OTP, verifies it with the server, and
 * performs subsequent actions based on the verification response. If the OTP
 * is invalid, an error message is displayed. If successful, user data is saved
 * to local storage, and optional claim or report actions are executed. The user
 * is then redirected to the home page, and their profile details are updated in
 * the redux store.
 *
 * @param {object} data - The form data containing the OTP value.
 * @param {string[]} data.otp - The OTP entered by the user.
 */

  const onSubmit = async (data: { otp: string[] }) => {
    const otp = data.otp.join('');
    if (otp.length !== 4) {
      setError('otp', {
        type: 'manual',
        message: staticTexts.otpMustBeOf4digits,
      });
      return;
    }
    try {
      startLoader();
      const storedUserData = sessionStorage.getItem('login-data');
      const claimItemData = sessionStorage.getItem('claim-item-data');
      const reportFoundItemData = sessionStorage.getItem('report-found-item-data');

      if (storedUserData) {
        const { phone } = JSON.parse(storedUserData) || {};

        const response = (await verifyLoginOTP({ phone, otp })) as any;
        sessionStorage.removeItem('login-data');

        localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, response.data.token);
        localStorage.setItem(LocalStorageKeys.USER_TYPE, response.data.user.type);

        if (claimItemData) {
          const payload = JSON.parse(claimItemData) || {};
          await claimItem(payload);
          sessionStorage.removeItem('claim-item-data');
          enqueueSnackbar(staticTexts.itemClaimedSuccessfully);
        }

        if (reportFoundItemData) {
          const payload = JSON.parse(reportFoundItemData) || {};
          await createItem(payload);
          sessionStorage.removeItem('report-found-item-data');
          enqueueSnackbar(staticTexts.itemPostedSuccessfully);
        }

        dispatch({
          type: UPDATE_LOGIN_STATUS,
          payload: {
            isLoggedIn: true,
          },
        });
        // Dismiss Loading
        stopLoader();

        const user = response.data.user
        // Store Default Language
        localStorage.setItem(LocalStorageKeys.LANGUAGE, user.defaultLanguage);

        // Set user details in the redux store
        dispatch({
          type: SET_PROFILE_DETAILS,
          payload: {
            _id: user?._id,
            name: user?.name,
            email: user?.email,
            profile: user?.profile,
            phone: user?.phone,
            gender: user?.gender,
            dob: user?.dob,
            address: user?.address,
            description: user?.description,
            coverProfile: user?.coverProfile,
            type: user?.type,
            defaultLanguage: user?.defaultLanguage,
          },
        });
        navigate('/home');
      }
    } catch (error: any) {
      stopLoader();
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    }
  };

  const handleResendOTP = async () => {
    try {
      startLoader();
      const loginData = sessionStorage.getItem('login-data');
      if (loginData) {
        const {
          phone: { prefix, number },
        } = JSON.parse(loginData) || {};
        await login({
          phone: {
            prefix: prefix.replace('+', ''),
            number,
          },
          type: UserType.INDIVIDUAL,
          loginWithOtp: true,
        });
      }
    } catch (error) {
      enqueueSnackbar((error as any)?.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className='flex flex-col items-center rounded-md py-2'>
        <Box
          className='sm:justify-center md:self-start'
          marginTop='45px'
        >
          <Controller
            name='otp'
            control={control}
            rules={{
              validate: (value) => value.every((v) => /^[0-9]$/.test(v)) || `OTP ${staticTexts.isRequired}`, // TODO - Mayank: Use translations for static text "OTP"
            }}
            render={({ field }) => (
              <OTPInput
                otp={field.value}
                onChange={(index: number, value: string) => {
                  const updatedOtp = [...field.value];
                  updatedOtp[index] = value;
                  field.onChange(updatedOtp);
                }}
                error={errors?.otp?.message}
                onResendOTP={handleResendOTP}
              />
            )}
          />
          <Typography
            width='100%'
            fontSize='12px'
            color='#4A403A'
            marginTop='24px'
            textAlign='left'
          >
            {staticTexts.tryLogginInWithPassword}{' '}
            <span
              className='underline text-accent cursor-pointer'
              onClick={() => navigate('/auth/login/password')}
            >
              {staticTexts.usePassword}
            </span>
          </Typography>
        </Box>
        <Button
          type='submit'
          className='px-6 py-2 rounded-lg w-fit text-center text-sm'
          buttonStyle={{ marginTop: '40px' }}
        >
          {staticTexts.loginWithOTP}
        </Button>
      </Box>
    </form>
  );
};
