class StringValidations {

    /**
     * @return TRUE if str don't Contain Any Letter or Contains only spaces
     *
     * @param str String
     * */
    static isBlank(str: String): boolean {
        return str.trim().length === 0
    }

    /**
     * @return TRUE if str don't Contain Any Letter
     *
     * @param str String
     * */
    static isEmpty(str: String): boolean {
        return str.length === 0
    }

    /**
     * @return TRUE if str is NULL or Don't Contain Any Letter or Contains only spaces
     *
     * @param str String
     * */
    static isNullOrBlank(str?: String | undefined): boolean {
        return str == null || str.trim().length === 0
    }

    /**
     * @return TRUE if str is NULL or Don't Contain Any Letter
     *
     * @param str String
     * */
    static isNullOrEmpty(str?: String | undefined): boolean {
        return str == null || str.length === 0
    }

    /**
     * @return TRUE if value is String
     * */
    static isString(value: unknown): value is string {
        return typeof value === "string";
    }

    /**
     * @return TRUE if value is Boolean
     * */
    static isBoolean(value: unknown): value is boolean {
        return typeof value === "boolean";
    }
}

export default StringValidations;
