import React, { useEffect } from "react";
import { PasswordInput, Button, useSnackbar } from "../../components";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../assets";
import { verifyResetPasswordOTP } from "../../services";
import { UserType } from "../../constants/AppConstants";

interface FormFields {
  newPassword: string;
  confirmPassword: string;
}

/**
 * The ResetPassword component is a form for resetting a user's password.
 *
 * It displays two password input fields for the new password and confirm new
 * password, and a submit button to save the new password.
 *
 * It also displays a cancel button to go back to the previous page.
 *
 * The component uses the react-hook-form library for form validation and
 * submission.
 *
 * When the form is submitted, it calls the verifyResetPasswordOTP API to
 * verify the OTP and update the user's password. If the API call is successful,
 * it navigates to the login page.
 *
 * If the API call fails, it displays an error message.
 *
 * It also displays a footer with a link to the contact support page.
 */
export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { email, code } = useParams();

  useEffect(() => {
    window.history.replaceState({}, document.title, "/reset-password");
  }, []);

  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const enqueueSnackbar = useSnackbar();

  /**
   * Handles the submission of the reset password form.
   *
   * It validates the new password and confirm new password fields to ensure
   * they match. If they do not match, it displays an error message.
   *
   * If the fields are valid, it calls the verifyResetPasswordOTP API to verify
   * the OTP and update the user's password. If the API call is successful, it
   * navigates to the login page.
   *
   * If the API call fails, it displays an error message.
   * @param {FormFields} data The form data containing the new password and
   * confirm new password values.
   */
  const handleSave = async (data: FormFields) => {
    try {
      if (data.newPassword !== data.confirmPassword) {
        enqueueSnackbar(staticTexts.passwordsDoNotMatch);
        return;
      }

      if (email && code) {
        await verifyResetPasswordOTP({
          email,
          code,
          type: UserType.BUSINESS,
          password: data.newPassword,
          confirmPassword: data.confirmPassword,
        });
        enqueueSnackbar(staticTexts.passwordUpdatedSuccessfully);
        navigate("/auth/businesslogin");
        return;
      }
      const otp = sessionStorage.getItem("otp");
      const storedUserData = sessionStorage.getItem("login-data");
      if (!storedUserData) {
        enqueueSnackbar(staticTexts.somethingWentWrong);
        return;
      }
      const phone = JSON.parse(storedUserData) || {};
      await verifyResetPasswordOTP({
        phone: phone.phone,
        type: UserType.INDIVIDUAL,
        code: otp,
        password: data.newPassword,
        confirmPassword: data.confirmPassword,
      });
      sessionStorage.removeItem("otp");
      sessionStorage.removeItem("login-data");
      enqueueSnackbar(staticTexts.passwordUpdatedSuccessfully);
      navigate("/auth/login");
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="flex flex-col items-center p-8 min-h-screen mx-auto max-w-max">
        {/* Title */}
        <Typography
          variant="body2"
          component="p"
          fontSize="26px"
          color="#4A403A"
          letterSpacing="0.01px"
        >
          {staticTexts.setNewPassword}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          fontSize="16px"
          color="#655B53FA"
          className="text-secondary-200 text-sm mt-2"
          marginBottom="48px"
        >
          {staticTexts.createNewPasswordForYourAccount}
        </Typography>

        {/* New Password Input */}
        <PasswordInput
          name="newPassword"
          label={staticTexts.newPassword}
          className="!mb-3"
          control={control}
          error={errors.newPassword}
          helperText={staticTexts.passwordHelperText}
          validationRules={{
            required: `${staticTexts.password} ${staticTexts.isRequired}`,
            minLength: {
              value: 6,
              message: staticTexts.passwordMustBeAtleast6char,
            },
          }}
        />
        
        {/* Confirm New Password Input */}
        <PasswordInput
          name="confirmPassword"
          label={staticTexts.confirmNewPassword}
          control={control}
          error={errors.confirmPassword}
          helperText={staticTexts.makeSureBothPasswordMatch}
          validationRules={{
            required: `${staticTexts.confirmPassword} ${staticTexts.isRequired}`,
            minLength: {
              value: 6,
              message: staticTexts.passwordMustBeAtleast6char,
            },
          }}
        />

        {/* Action Buttons */}
        <div className="flex gap-4 mt-8">
          <Button
            type="button"
            variant="outlined"
            buttonStyle={{
              border: "1px solid #C9C2B4",
              color: "#111111D8",
            }}
            className="px-6 py-2 rounded-md hover:bg-gray-100"
            onClick={() => navigate(-1)}
          >
            {staticTexts.cancel}
          </Button>
          <Button type="submit">{staticTexts.saveNewPassword}</Button>
        </div>

        {/* Footer */}
        <Typography
          variant="body1"
          fontSize="12px"
          color="#776E65"
          marginTop="40px"
          className="text-center cursor-pointer"
          onClick={() => navigate("/auth/signup")}
        >
          {staticTexts.needHelp}{" "}
          <span
            className="underline"
            onClick={() => navigate("/contact-support")}
          >
            {staticTexts.contactSupport}
          </span>
        </Typography>
      </div>
    </form>
  );
};
