import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

const GoogleAd = () => {
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    // Load Google AdSense script in test mode
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-123456789";
    script.crossOrigin = "anonymous";
    script.onload = () => {
      try {
        // Initialize the mock ad test mode
        (window as any).adsbygoogle = (window as any).adsbygoogle || [];
        const adConfig = (o: any) => { (window as any).adsbygoogle.push(o); };
        adConfig({
          google_ad_client: "ca-pub-123456789",
          google_ad_format: "auto",
          google_adtest: "on", // Enable test mode
          google_ad_slot: "1234567890", // You can set a mock slot here
        });
      } catch (error) {
        console.error('AdSense error:', error);
      }
    };
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const closeAd = () => {
    setIsVisible(false);
  };

  return (
    <div className={`${!isVisible && 'hidden'} fixed bottom-4 right-4 rounded-2xl animate-slide-up z-50 h-[400] w-fit`}>
      <button
        onClick={closeAd}
        className='absolute top-2 ltr:right-2 rtl:left-2 text-sm text-gray-600 hover:text-gray-900'
        aria-label='Close Ad'
      >
        ✕
      </button>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', height: '200px', width: '300px' }}
        data-ad-client="ca-pub-123456789"
        data-ad-slot="1234567890"  // Replace with mock ad slot
        data-ad-format="auto"
        data-adtest="on"  // Test mode
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default GoogleAd;
