import React from 'react';

interface CheckboxProps {
    label?: string;
    checked?: boolean;
    onChange: (isChecked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked = false, onChange }) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <div>
            <label className='text-xs text-[#776E65]' style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                    style={{
                        width: '18px',
                        height: '18px',
                        accentColor: '#FF6F61',
                        borderColor: '#776E65',
                        backgroundColor: checked ? '#FF6F61' : 'transparent',
                        outline: 'none',
                        verticalAlign: 'middle',
                        appearance: 'checkbox'
                    }}
                    className='cursor-pointer'
                />
                {label && (<span style={{ marginLeft: '8px' }}>{label}</span>)}
            </label>
        </div>
    );
};

export default Checkbox;
