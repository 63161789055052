import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Menu, MenuItem, Tooltip, Typography, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';
import { getStaticTexts, productPlaceHolder } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { handleCharacterLimit, textTransform } from '../../../services/common';
import moment from 'moment';
import { formatDate } from '../../../utils';

// Props Interface
interface ProductListItemProps {
  itemId?: string;
  isDeleteIconDisabled?: boolean;
  title: string;
  description: string;
  location: string;
  visibility: string;
  date: string;
  imageUrl: string;
  onEdit?: () => void;
  onDelete?: () => void;
  showDate?: boolean;
  customActions?: React.ReactNode;
  showLocation?: boolean;
  showVisibility?: boolean;
  showActions?: boolean;
  showClaimButton?: boolean;
  showMoreOptions?: boolean;
  minWidth?: string;
  showEdit?: boolean;
  menuOptions?: { label: string; onClick: () => void }[];
  showResolvedTag?: boolean
}

// Styled components
const Container = styled(Box)(({ minWidth }: { minWidth?: string }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F7EFDE',
  justifyContent: 'space-between',
  borderRadius: '12px',
  minWidth: minWidth || '330px',
  padding: '8px',
}));

const FlexBox = styled(Box)(({ gap }: { gap?: string }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: gap || '8px',
}));

const ImageWrapper = styled(Box)({
  height: '64px',
  width: '64px',
  borderRadius: '6px',
  overflow: 'hidden',
});

const InfoWrapper = styled(Box)({
  width: 'fit-content',
});

const IconTextGroup = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'rowGap' && prop !== 'columnGap',
})<{
  rowGap?: string;
  columnGap?: string;
}>(({ rowGap, columnGap }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  rowGap: rowGap || '4px',
  columnGap: columnGap || '8px',

  '@media (max-width: 350px)': {
    flexDirection: 'column',
    gap: '4px',
    alignItems: 'flex-start',
  },
}));

const IconTextWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  lineHeight: 1,
});

const ActionWrapper = styled(Box)({
  border: '1px solid #C9C2B4',
  height: '20px',
  width: '80px',
});

const ProductList: React.FC<ProductListItemProps> = ({
  itemId,
  isDeleteIconDisabled,
  title,
  description,
  location,
  visibility,
  date,
  imageUrl,
  onEdit,
  onDelete,
  customActions,
  showDate = true,
  showLocation = true,
  showVisibility = true,
  showActions = true,
  showClaimButton = false,
  showMoreOptions = true,
  menuOptions = [],
  minWidth,
  showEdit = true,
  showResolvedTag = false,
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [anchorEl, setAnchorEl] = React.useState<null | (EventTarget & SVGSVGElement)>(null);

  const navigate = useNavigate();
  const handleMenuClick = (
    event: React.MouseEvent<SVGSVGElement>,
    setAnchor: React.Dispatch<React.SetStateAction<null | SVGSVGElement>>
  ) => {
    setAnchorEl(event.currentTarget as SVGSVGElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container minWidth={minWidth}>
      <FlexBox>
        <ImageWrapper>
          <img
            className='h-full w-full object-cover rounded-md'
            src={imageUrl || productPlaceHolder}
            alt='product'
          />
        </ImageWrapper>
        <InfoWrapper>
          <Typography
            variant='body2'
            color='#4A403A'
            fontSize='14px'
            sx={{ textTransform: 'capitalize' }}
          >
            {title} {
              showResolvedTag &&  <span className='bg-[#D0F0D2] text-center rounded-lg text-[#3DA144] p-1 !text-xs font-normal'>Owner found</span>
            }
          </Typography>
          <Typography
            variant='body1'
            color='#655B53FA'
            fontSize='12px'
            letterSpacing='-0.14px'
          >
            {handleCharacterLimit(description, 40)}
          </Typography>

          <IconTextGroup
            columnGap='12px'
            rowGap='4px'
          >
            {showLocation && (
              <IconTextWrapper>
                <LocationOnIcon sx={{ color: '#776E65', fontSize: '16px' }} />
                <Typography
                  variant='body1'
                  fontSize='12px'
                  color='#776E65'
                >
                  {handleCharacterLimit(textTransform(location), 40)}
                </Typography>
              </IconTextWrapper>
            )}
            {showVisibility && (
              <IconTextWrapper>
                <PublicIcon sx={{ color: '#776E65', fontSize: '16px' }} />
                <Typography
                  variant='body1'
                  fontSize='12px'
                  color='#776E65'
                >
                  {visibility ? staticTexts.public : staticTexts.private}
                </Typography>
              </IconTextWrapper>
            )}
            {showDate && (
              <IconTextWrapper>
                <AccessTimeFilledIcon sx={{ color: '#776E65', fontSize: '16px' }} />
                <Typography
                  variant='body1'
                  fontSize='12px'
                  color='#776E65'
                >
                  {formatDate(date)}
                </Typography>
              </IconTextWrapper>
            )}
          </IconTextGroup>
        </InfoWrapper>
      </FlexBox>

      {/* Actions wrapper */}
      {showActions && (
        <div className='flex items-center'>
          <div className='md:block hidden'>
            {customActions ? (
              customActions
            ) : (
              <>
                {showEdit && (
                  <IconButton
                    aria-label='edit'
                    size='small'
                    onClick={onEdit}
                    style={{ margin: 0 }}
                  >
                    <EditIcon
                      style={{ margin: 0 }}
                      fontSize='small'
                    />
                  </IconButton>
                )}
                {!isDeleteIconDisabled && (
                  <IconButton
                    aria-label='delete'
                    size='small'
                    color='error'
                    onClick={onDelete}
                  >
                    <DeleteIcon
                      fontSize='small'
                    />
                  </IconButton>
                )}
              </>
            )}
          </div>
          {showMoreOptions && (
            <IconButton
              aria-label='more'
              size='small'
            >
              <MoreVertIcon
                fontSize='small'
                onClick={(event) => setAnchorEl(event.currentTarget)}
              />
            </IconButton>
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.label}
                onClick={()=>{
                  option.onClick();
                  handleClose();
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {showClaimButton && !showMoreOptions && (
        <Button
          onClick={() => {
            navigate(`/item-detail/${itemId}`);
          }}
        >
          {staticTexts.claimItem}
        </Button>
      )}
      {showMoreOptions && showClaimButton && (
        <Fragment>
          <MoreVertIcon
            sx={{ fontSize: '16px', cursor: 'pointer' }}
            onClick={(event) => handleMenuClick(event, setAnchorEl)}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => {
                  option.onClick();
                  handleClose();
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Fragment>
      )}
    </Container>
  );
};

export default ProductList;
