import React, { FC } from "react";
import { Backdrop } from "@mui/material";
import "./style.css";
interface ILoader {
  className?: string;
}

const Loader: FC<ILoader> = ({ className }) => {
  return (
    <>
      <Backdrop
        className={`backdrop ${className}`}
        sx={{ color: "fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open
      >
        <img className="loader" src="/loader.gif" alt="Loader" />
      </Backdrop>
    </>
  );
};
export default Loader;
