import React from 'react';
import { Container, Typography, Box } from '@mui/material';

export const About = () => {
  return (
    <div className='text-secondary-200 min-h-screen'>
      <Container
        maxWidth='lg'
        className='py-16'
      >
        {/* Header Section */}
        <div className='flex justify-center items-center'>
          <Typography
            variant='h4'
            className='font-semibold text-secondary-400'
          >
            About Lqeet
          </Typography>
        </div>

        {/* Content Section */}
        <Box className='mt-8 space-y-8'>
          
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Lorem, ipsum
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus dicta, repellat praesentium error
              repellendus reprehenderit maiores ab ipsa aliquam sit voluptatibus sed reiciendis culpa totam incidunt ex
              a. Explicabo, aliquam?
            </Typography>
          </Box>

         
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Lorem ipsum dolor sit
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, aliquam. <br />
              2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, voluptates! <br />
              3. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis possimus officia minima veritatis? Rem,
              numquam ipsam delectus optio eum a?
            </Typography>
          </Box>

          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Lorem ipsum dolor sit amet consectetur
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam totam animi incidunt dolores molestias ab
              aperiam dignissimos soluta facere modi!
            </Typography>
          </Box>

          {/* User Rights */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
             Lorem ipsum dolor
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sapiente, nemo. Vel ipsum porro perferendis modi
              architecto voluptatibus molestiae ipsa nobis.{' '}
              <a
                href='mailto:privacy@Lqeet.com'
                className='text-accent text-sm'
              >
                privacy@lqeet.com
              </a>
              .
            </Typography>
          </Box>

          {/* Updates to Policy */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Lorem ipsum dolor sit amet
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam exercitationem eaque ipsum officiis soluta.
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
