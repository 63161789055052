import React, { useState } from 'react';
import { Modal, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { on } from 'events';

// Styled Modal Component
const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
}));

// Styled Close Button
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: '#776E65',
  backgroundColor: '#FFE0B5'
}));

// Styled Image for Large Display
const LargeImage = styled('img')(() => ({
  maxWidth: '90vw',
  maxHeight: '90vh',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
}));

const ImageViewer: React.FC<{ imageUrl: string; open: boolean; onClose: () => void }> = ({
  imageUrl,
  open,
  onClose,
}) => {
  return (
    <div className='flex flex-wrap gap-4'>
      {/* Custom Styled Modal */}
      <StyledModal
        open={open}
        onClose={onClose}
      >
        <div className='relative'>
          {/* Close Button */}
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          {/* Large Image */}
          <LargeImage
            src={imageUrl}
            alt='Full View'
          />
        </div>
      </StyledModal>
    </div>
  );
};

export default ImageViewer;
