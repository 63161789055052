import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContentWrapper, DateInput, Dropdown, Input, PhoneNumberInput, UploadBox, useSnackbar } from '../../components';
import { defaultProfileImg, getStaticTexts } from '../../assets';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { textTransform, updateProfile } from '../../services/common';
import moment from 'moment';
import CustomButton from '../../components/Button/CustomButton';
import { AppButtonVariant, Language, LocalStorageKeys } from '../../constants/AppConstants';
import { startLoader, stopLoader } from '../../utils';

export const Profile = () => {
  // UI States
  const [isEdit, setIsEdit] = useState(false);

  // Language Selector
  const language = useSelector((state: any) => state.language);

  // Static Texts (Translations)
  const staticTexts = getStaticTexts(language);

  // User Profile Data
  const user = useSelector((state: any) => state.profile);

  // Dial Code Value
  const [dialCode, setDialCode] = useState(user?.phone?.prefix || '+966');

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<{
    fullName: string;
    prefix: string;
    number: string;
    email: string;
    dob: Date;
    language: string;
    gender: string;
  }>({
    defaultValues: {
      fullName: '',
      email: '',
      prefix: '',
      number: '',
      dob: new Date(),
      language: '',
      gender: '',
    },
  });

  const [images, setImages] = useState<string[]>([]);
  const enqueueSnackbar = useSnackbar();

  useEffect(() => {
    setProfileData();
  }, [user]);

  /**
   * Set Profile Data in the Fields
   */
  const setProfileData = () => {
    setValue('fullName', user?.name);
    setDialCode('+' + user?.phone?.prefix);
    setValue('prefix', user?.phone?.prefix);
    setValue('number', user?.phone?.number);
    setValue('email', user?.email);
    setValue('dob', moment(user?.dob).toDate());
    setValue('language', user?.defaultLanguage === Language.EN ? staticTexts.english : staticTexts.arabic);
    setValue('gender', textTransform(user?.gender));
    setImages([user?.profile]);
  };

  const handleFileUrls = (fileUrls: string[]) => {
    setImages(fileUrls);
  };

  /**
   * Handle Cancel Button Click
   */
  const onCancelClick = () => {
    // Disable Edit Mode
    setIsEdit(!isEdit);

    // Reset the form fields
    setProfileData();
  };

  const onSubmitClick = () => {
    if (isEdit) {
      setIsEdit(!isEdit);
      handleSubmit(onSubmit)();
    } else {
      setIsEdit(!isEdit);
    }
  };

  /**
   * API Call: Update Profile
   */
  const onSubmit = async (data: any) => {
    try {
      // Show Loading
      startLoader();

      // Prepare Request Payload
      const language =
        data?.language === staticTexts.english || data?.language?.label === staticTexts.english
          ? Language.EN
          : Language.AR;

      const payload = {
        name: data?.fullName,
        email: data?.email,
        prefix: data?.phone?.prefix,
        number: data?.phone?.number,
        dob: data?.dob,
        gender: data?.gender.toUpperCase(),
        defaultLanguage: language,
        profile: images[0],
      };

      // API Call Update Profile
      await updateProfile(payload);

      // Show Message
      enqueueSnackbar(staticTexts.profileUpdatedSuccessfully);
    } catch (error: any) {
      if (error.response.status === 409) {
        localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
        localStorage.removeItem(LocalStorageKeys.USER_TYPE);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_TOKEN);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_USER_TYPE);
      }

      // Show Message
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };

  const handleCountryCodeChange = (dialCode: SelectChangeEvent<string>) => {
    setDialCode(String(dialCode));
    setValue('prefix', String(dialCode).replace('+', ''));
  };

  return (
    <ContentWrapper
      title={isEdit ? staticTexts.editProfile : staticTexts.myAccount}
      alignText='left'
    >
      <Box className='flex justify-center items-center flex-col'>
        {/* Profile Picture */}
        <Typography
          className='text-sm'
          marginTop='10px'
          marginBottom='10px'
        >
          {staticTexts.profilePicture}
        </Typography>

        {/* Profile Picture */}
        <UploadBox
          defaultImage={images[0] || defaultProfileImg}
          onFileChange={handleFileUrls}
        />

        {/* Profile Upload Help Text */}
        <Typography
          className='text-help_text'
          fontSize='12px'
          marginTop='10px'
          marginBottom='32px'
        >
          {staticTexts.profilePicInfo}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Full Name Input */}
        <Input
          name='fullName'
          className='!mb-6'
          label={staticTexts.fullName}
          placeholder={staticTexts.enterYourFullName}
          control={control}
          error={errors.fullName}
          isRequired
          disabled={!isEdit}
        />

        <PhoneNumberInput
          name='number'
          className='!mb-6'
          label={staticTexts.phoneNumber}
          helperText={errors?.number?.message}
          countryCode={dialCode}
          control={control}
          error={errors?.number}
          handleCountryCodeChange={handleCountryCodeChange}
          helperTextColor='#776E65'
          validationRules={{
            required: `${staticTexts.phoneNumber} ${staticTexts.isRequired}`,
          }}
          disabled={true}
        />

        {/* Email Input */}
        <Input
          name='email'
          className='!mb-6'
          label={staticTexts.emailAddress}
          placeholder={staticTexts.enterYourEmail}
          control={control}
          error={errors.email}
          disabled={!isEdit}
        />

        {/* Date Input */}
        <Box
          className='w-full'
          display={{
            xs: 'block',
            md: 'flex',
          }}
          marginBottom={{
            xs: '24px',
            md: '12px',
          }}
          gap='8px'
        >
          <Box className='md:w-1/2 w-full'>
            <DateInput
              name='dob'
              className='!mb-4 w-full'
              label={staticTexts.enterDOB}
              control={control}
              error={errors.dob}
              helperText={errors.dob?.message}
              validationRules={{
                required: staticTexts.dateOfBirthIsRequired,
                validate: (value) => {
                  const dob = new Date(value);
                  const today = new Date();
                  let age = today.getFullYear() - dob.getFullYear();
                  const monthDiff = today.getMonth() - dob.getMonth();
                  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
                    age--;
                  }
                  return age >= 18 || staticTexts.youMustBeAtLeast18YearsOld;
                },
              }}
              disabled={!isEdit}
            />
          </Box>

          <Box className='md:w-1/2 w-full'>
            <Dropdown
              name='gender'
              // dropdownStyle={{ marginBottom: '4px' }}
              control={control}
              options={[staticTexts.male, staticTexts.female, staticTexts.others]}
              labelText={staticTexts.selectGender}
              isRequired={true}
              error={errors.gender}
              disabled={!isEdit}
            />
          </Box>
        </Box>

        {/* Language Dropdown Input */}
        <Dropdown
          name='language'
          control={control}
          options={[
            { label: staticTexts.arabic, value: Language.AR },
            { label: staticTexts.english, value: Language.EN },
          ]}
          labelText={staticTexts.preferredLanguage}
          isRequired
          error={errors.language}
          disabled={!isEdit}
        />

        <Box className='flex justify-between mt-10'>
          {/* Cancel Button */}
          {isEdit && (
            <CustomButton
              buttonStyle={{ paddingX: 0, flexGrow: 0, marginLeft: -1.2 }}
              variant={AppButtonVariant.TEXT}
              title={staticTexts.cancel}
              onPress={onCancelClick}
            />
          )}

          <Box className='flex justify-end w-full'>
            <CustomButton
              title={isEdit ? staticTexts.saveChanges : staticTexts.editProfile}
              onPress={onSubmitClick}
            />
          </Box>
        </Box>
      </form>
    </ContentWrapper>
  );
};
