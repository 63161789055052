import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { CustomButtonProps } from '../../interfaces';


/**
 * A reusable Material-UI Button component with a default height of 32px.
 *
 * Props:
 * - `variant`: The variant of the button. Can be 'contained' or 'outlined'. Defaults to 'contained'. Optional.
 * - `color`: The color of the button. Can be any valid Material-UI color prop. Defaults to 'primary'. Optional.
 * - `onClick`: A function to be called when the button is clicked. Required.
 * - `children`: The content of the button. Required.
 * - `buttonStyle`: A custom style object to apply to the button. Optional.
 * - `[key: string]`: Any additional props passed to the `MuiButton` component. Optional.
 *
 * Returns:
 * A Material-UI Button component with the specified props.
 *
 **/
export const Button: React.FC<CustomButtonProps> = ({
  variant = 'contained',
  color = 'primary',
  onClick,
  children,
  buttonStyle,
  ...props
}) => {
  return (
    <MuiButton
      variant={variant}
      color={color}
      onClick={onClick}
      disableRipple
      sx={{ ...buttonStyle, height: '32px' }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};
