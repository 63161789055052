import { SET_VIEW } from '../../actions/my-items';

const initialState = 'Grid';

export const itemsViewReducer = (state = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_VIEW: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
