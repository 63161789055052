import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import theme from './assets/theme';
import { ThemeProvider } from '@emotion/react';
import { SnackbarProvider } from './components';
import { Provider, useSelector } from 'react-redux';
import { createSocketConnection } from './services';
import Loader from './components/Loader';
import { LocalStorageKeys } from './constants/AppConstants';

const RootComponent = () => {
  const language = useSelector((state: any) => state.language);
  const direction = language === 'ar' ? 'rtl' : 'ltr';
  const token = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
  const loading = useSelector((state: any) => state.loader);

  useEffect(() => {
    if (token) {
      createSocketConnection(token)
        .then(() => console.log('socket connected'))
        .catch((err) => console.log(err));
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme(direction)}>
      <SnackbarProvider>
        <div style={{ fontFamily: language === 'ar' ? 'Tajawal, Sans-serif' : 'Roboto, Sans-serif' }}>
          {loading && <Loader/> }
          <App/>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <RootComponent />
  </Provider>
);

reportWebVitals();
