import React, { useEffect, useState } from 'react';
import { GoogleMap as GMap, Marker, useLoadScript } from '@react-google-maps/api';
import { TextField, IconButton, Box, List, ListItem, ListItemButton, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';

interface GoogleMapProps {
  onLocationSelect?: (location: { lat: number; lng: number; description: string }) => void;
}

/**
 * GoogleMap is a React functional component that integrates with Google Maps API
 * to provide an interactive map and location search functionality.
 *
 * Props:
 * - onLocationSelect (optional): A callback function that is called when a location is selected,
 *   providing an object with latitude, longitude, and description.
 *
 * The component uses the `useLoadScript` hook to load the Google Maps API script and initializes
 * the autocomplete service. It maintains state for user search input, location predictions,
 * map center, selected location, and marker position.
 *
 * Features:
 * - Displays a Google Map with a marker that updates on map clicks or prediction selections.
 * - Provides a search input for location predictions using Google Places Autocomplete.
 * - Fetches and displays address descriptions based on map clicks or selected predictions.
 *
 * Dependencies:
 * - @react-google-maps/api for map and marker components.
 * - @mui/material for UI components like TextField, IconButton, and List.
 * - Google Maps JavaScript API with Places and Geocoder services.
 */

export const GoogleMap: React.FC<GoogleMapProps> = ({ onLocationSelect }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAP_API_KEY),
    libraries: ['places'],
  });

  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [mapCenter, setMapCenter] = useState({ lat: 23.685, lng: 87.086 });
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    if (isLoaded && !autocompleteService && window.google) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
    }
  }, [autocompleteService, isLoaded]);

  const fetchPredictions = (input: string) => {
    if (autocompleteService && input.trim().length > 0) {
      autocompleteService.getPlacePredictions({ input }, (results) => {
        setPredictions(results || []);
      });
    } else {
      setPredictions([]);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    fetchPredictions(value);
  };

  const fetchAddress = async (lat: number, lng: number) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ location: { lat, lng } });
      console.log('response', response);
  
      if (response.results?.length) {
        const addressComponents = response.results[0].address_components;
  
        const findComponent = (type: string) =>
          addressComponents.find((component) => component.types.includes(type))?.long_name || '';
  
        const locality = findComponent('locality');
        const administrativeLevel3 = findComponent('administrative_area_level_3');
        const administrativeLevel1 = findComponent('administrative_area_level_1');
        const country = findComponent('country');
  
        // Combine into a single string
        return `${locality}, ${administrativeLevel3}, ${administrativeLevel1}, ${country}`;
      }
  
      return '';
    } catch (error) {
      console.error('Failed to fetch location description:', error);
      return 'Unknown location';
    }
  };
  

  const handleMapClick = async (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const description = await fetchAddress(lat, lng);

    setMarkerPosition({ lat, lng });
    setSelectedLocation({ lat, lng });
    setMapCenter({ lat, lng });

    if (onLocationSelect) {
      onLocationSelect({ lat, lng, description });
    }
  };

  const handlePredictionClick = (prediction: google.maps.places.AutocompletePrediction) => {
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails({ placeId: prediction.place_id }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && result?.geometry?.location) {
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        const description = result.formatted_address || prediction.description || '';

        setMarkerPosition({ lat, lng });
        setSelectedLocation({ lat, lng });
        setMapCenter({ lat, lng });

        if (onLocationSelect) {
          onLocationSelect({ lat, lng, description });
        }
      }
    });
    setInputValue(prediction.description);
    setPredictions([]);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
      {/* Interactive Map */}
      <Box sx={{ width: '100%', height: '300px', marginBottom: '16px' }}>
        <GMap
          mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '8px' }}
          center={mapCenter}
          zoom={14}
          onClick={handleMapClick}
        >
          {markerPosition && <Marker position={markerPosition} />}
        </GMap>
      </Box>

      {/* Search Input */}
      <Box sx={{ position: 'relative', width: '100%' }}>
        <TextField
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          placeholder='Search location'
          sx={{
            backgroundColor: 'white',
            padding: '4px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #FF6F61 !important',
              borderRadius: '12px',
            },
          }}
          slotProps={{
            input: {
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            },
          }}
        />

        <List sx={{ borderRadius: 2, maxHeight: 300, overflowY: 'auto' }}>
          {predictions.map((prediction) => (
            <ListItem
              disablePadding
              key={prediction.place_id}
            >
              <ListItemButton onClick={() => handlePredictionClick(prediction)}>
                <LocationOnIcon sx={{ marginRight: 1 }} />
                <Box
                  borderBottom='1px solid #FFE0B5'
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <Typography>{prediction.structured_formatting.main_text}</Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                  >
                    {prediction.structured_formatting.secondary_text}
                  </Typography>
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
