import { SET_CLAIM_ITEM_DETAILS } from '../../actions';

const initialState = {};

export const claimReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CLAIM_ITEM_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
