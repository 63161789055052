import React from 'react';
import { Modal as MuiModal, Box, Typography, Button } from '@mui/material';
import { CustomModalProps } from '../../interfaces';
import STORE from '../../redux/store/index';
import { getStaticTexts } from '../../assets';

const language = STORE.getState().language;
const staticTexts = getStaticTexts(language);
export const Modal: React.FC<CustomModalProps> = ({
  open,
  title,
  children,
  onClose,
  onConfirm,
  confirmText = staticTexts.confirm,
  cancelText =  staticTexts.cancel,
  customStyles,
  buttonContainerStyle,
  confirmButtonStyle,
  cancelButtonStyle,
  ...modalProps
}) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      {...modalProps}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '409px',
          bgcolor: '#FFF6E4',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: 'center',
          ...customStyles,
        }}
      >
        {title && (
          <Typography
            variant='h6'
            component='h2'
            gutterBottom
            sx={{ fontWeight: 'bold', mb: 1 }}
          >
            {title}
          </Typography>
        )}
        {children && <Box sx={{ mb: 3 }}>{children}</Box>}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, ...buttonContainerStyle }}>
          <Button
            onClick={onClose}
            variant='text'
            sx={{ borderRadius: '8px', border: '1px solid #C9C2B4', color: '#4A403A', ...cancelButtonStyle }}
          >
            {cancelText}
          </Button>
          {onConfirm && (
            <Button
              onClick={onConfirm}
              variant='contained'
              sx={{
                bgcolor: '#FF6F59',
                color: 'white',
                borderRadius: '8px',
                '&:hover': { bgcolor: '#E86450' },
                ...confirmButtonStyle,
              }}
            >
              {confirmText}
            </Button>
          )}
        </Box>
      </Box>
    </MuiModal>
  );
};
