import { Box, Typography } from '@mui/material';
import { foundSomething, getStaticTexts } from '../../../../assets';
import { Button } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * BusinessAddFirstItem component
 * @returns a JSX element for the business add first item screen
 * @description This component is used to display the add first item screen for business users
 * @example
 * <BusinessAddFirstItem />
 */
export const BusinessAddFirstItem = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any)=> state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <Box
      width='50%'
      marginX='auto'
      minWidth='350px'
      className='pb-20'
    >
      <Typography
        variant='h4'
        fontSize='26px'
        letterSpacing='0.01px'
        textAlign='center'
        sx={{ marginTop: { xs: 4, md: 7 }, marginBottom: { xs: 4, md: 7 } }}
      >
       {staticTexts.addYourFirstItemOrSearchLostAndFound}
      </Typography>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        bgcolor='#FFF7E6'
        borderRadius='8px'
        padding='40px'
      >
        <img
          src={foundSomething}
          alt='found-img'
          className="rtl:scale-x-[-1]"
        />

        <Typography
          color='#4A403A'
          fontSize='16px'
          textAlign='center'
          marginTop='40px'
          fontWeight='500'
        >
          {staticTexts.weHaveFoundAnItem}
        </Typography>

        <Typography
          color='#655B53FA'
          fontSize='14px'
          textAlign='center'
          marginTop='8px'
        >
         {staticTexts.logDetailsOfTheItem}
        </Typography>

        <Button
          buttonStyle={{ background: '#FF6F61', marginTop: '40px' }}
          onClick={() => navigate('/report-found-item')}
        >
         {staticTexts.reportFoundItem}
        </Button>
      </Box>
    </Box>
  );
};
