import React, { Fragment, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { ControlToolbar } from '../../../components';
import ProductCard from '../../../components/Layouts/ProductCard';
import FilterDrawer from '../../../components/Layouts/FilterDrawer';
import ProductList from '../../../components/Layouts/ProductList';
import { useDispatch, useSelector } from 'react-redux';
import NoItems from '../../../components/Layouts/NoItems';
import { SET_DATE_FILTERS, SET_SORT_FILTERS } from '../../../redux';
import CustomButton from '../../../components/Button/CustomButton';
import { getStaticTexts } from '../../../assets';
import { AppButtonVariant } from '../../../constants/AppConstants';
import StringFormatting from '../../../utils/extensions/StringFormatting';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Remove unused imports
// TODO - Mayank: Remove unused states
// TODO - Mayank: Remove consoles
// TODO - Mayank: Remove commented code

const SearchItems = () => {
  // Get Translations
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 10,
  });

  const [viewType, setViewType] = useState('Grid');
  const [open, setOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);
  const [sortFilter, setSortFilter] = useState(null);
  const [page, setPage] = useState(1);
  const products = useSelector((state: any) => state.searchProducts);
  const searchFilter = useSelector((state: any) => state.searchFilters);
  const dispatch = useDispatch();

  // Debounce function to optimize API calls
  const debounce = (func: Function, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // const fetchProducts = useCallback(async () => {
  //   try {
  //     // Show Loading
  //     startLoader();
  //     const params: any = {
  //       page: page,
  //       limit: meta.limit,
  //     };

  //     // API Call
  //     const response = await searchItems(
  //       {
  //         text: searchFilter.text ?? '',
  //         ...(searchFilter.category && { categoryId: searchFilter.category }),
  //         ...((searchFilter?.location?.lat && searchFilter?.location?.lng) && { location: searchFilter.location }),
  //         ...(dateFilter ? { startDate: (dateFilter as any)?.date?.from, endDate: (dateFilter as any)?.date?.to } : {}),
  //         ...(sortFilter ? { sort: (sortFilter as any)?.sort?.value } : {}),
  //       },
  //       params
  //     ) as any;
  //     setMeta({
  //       totalPages: response?.meta?.totalPages || 0,
  //       page: response?.meta?.page || 1,
  //       limit: response?.meta?.limit || 20,
  //     });
  //     dispatch({
  //       type: SET_SEARCH_PRODUCTS,
  //       payload: page > 1
  //         ? [...products, ...(response as any)?.items || []]
  //         : (response as any)?.items || [],
  //     });
  //   } catch (error) {
  //     console.error('Error fetching products:', error);
  //   } finally {

  //     // Dismiss Loading
  //     stopLoader();
  //   }
  // }, []);

  // const debouncedFetchProducts = useCallback(debounce(fetchProducts, 500), [fetchProducts]);

  // useEffect(() => {
  //   debouncedFetchProducts();
  // }, [page]);

/*************  ✨ Codeium Command ⭐  *************/
/**
 * Updates the view type state with the given value.
 * 
 * @param value - The new view type to be set.
 */

/******  ff3eb276-a23b-4d90-94d3-8850eac4d5f9  *******/
  const handleViewTypeChange = (value: string) => {
    setViewType(value);
  };

  const toggleDrawer = (state: boolean) => {
    setOpen(state);
  };

  const handleMobileFilter = (filters: any) => {
    const { viewMode } = filters;
    setViewType(viewMode);
    toggleDrawer(false);
  };

  const handleDateFilter = (filters: any) => {
    const {
      date: { from, to },
    } = filters || {};
    dispatch({
      type: SET_DATE_FILTERS,
      payload: {
        startDate: from,
        endDate: to,
      },
    });
  };

  const handleSorting = (filters: any) => {
    const {
      sort: { value },
    } = filters || {};
    dispatch({
      type: SET_SORT_FILTERS,
      payload: value,
    });
  };

  return (
    <Box className='w-full mb-20'>
      <Box
        className='mx-auto'
        width={{
          xs: '92%',
          md: '80%',
        }}
      >
        <ControlToolbar
          selectedTab={`${StringFormatting.capitalizeFirstLetterOfEveryWord(searchFilter.categoryName) ?? ''}`}
          viewType={viewType}
          onViewChange={handleViewTypeChange}
          itemCount={products.length}
          showButton={false}
          handleFilters={() => setOpen(true)}
          handleDateFilter={handleDateFilter}
          handleSorting={handleSorting}
          onSearch={(event: any) => {}}
        />
        {products?.length > 0 ? (
          <Fragment>
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
              width='100%'
            >
              {viewType === 'List' ? (
                products?.map((product: any, index: number) => (
                  <div
                    className='mb-2'
                    key={index}
                  >
                    <ProductList
                      title={product?.name}
                      itemId={product?._id}
                      description={product?.description}
                      location={product?.location?.name}
                      visibility={product?.visibileToEveryOne}
                      date={product?.eventDate}
                      imageUrl={product?.images[0]}
                      showActions={false}
                      showClaimButton={true}
                      showMoreOptions={false}
                      onDelete={() => console.log('Delete clicked')}
                    />
                  </div>
                ))
              ) : (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr 1fr',
                      sm: '1fr 1fr 1fr 1fr',
                      lg: '1fr 1fr 1fr 1fr 1fr',
                    },
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  {products?.map((product: any, index: number) => (
                    <ProductCard
                      key={index}
                      itemId={product._id}
                      images={product.images}
                      itemCategory={product?._category?.name}
                      itemName={product.name}
                      description={product.description}
                      location={product?.location?.name}
                      date={product.eventDate}
                      type={product.type}
                      showActions={false}
                      showPrivacy={false}
                      showClaimButton={true}
                    />
                  ))}
                </Box>
              )}

              {meta.page < meta.totalPages && (
                <div className='flex justify-center mt-4'>
                  <CustomButton
                    variant={AppButtonVariant.OUTLINED}
                    buttonStyle={{ backgroundColor: '#FFF7E6', borderColor: '#FFF7E6' }}
                    textColor='#4A403A'
                    title={staticTexts.showMoreItems}
                    onPress={() => {
                      setPage(page + 1);
                    }}
                  />
                </div>
              )}
            </Box>

            <FilterDrawer
              onApply={handleMobileFilter}
              isOpen={open}
              toggleDrawer={toggleDrawer}
            />
          </Fragment>
        ) : (
          <NoItems />
        )}
      </Box>
    </Box>
  );
};

export default SearchItems;
