import React, { Fragment, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../assets';
import { OTPInput, useSnackbar } from '../../../components';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';
import { Controller, useForm } from 'react-hook-form';
import { resetPassword } from '../../../services';
import { Box, Typography } from '@mui/material';
import { startLoader, stopLoader } from '../../../utils';
import { UserType } from '../../../constants/AppConstants';


/**
 * Forgot Password Page
 *
 * This page allows the user to enter their email address and receive a reset password link.
 * The link is sent by email and is valid for 30 minutes.
 * The user can request a new link if they don't receive the email.
 * The page is protected by a loader while the API call is in progress.
 * If the API call fails, an error message is displayed.
 * If the API call succeeds, the user is redirected to the login page.
 */
const ForgotPassword = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), []);
  const enqueueSnackbar = useSnackbar();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: ['', '', '', ''],
      email: '',
    },
  });

  const onSubmit = async (data: { otp: string[]; email: string }) => {
    const storedUserData = sessionStorage.getItem('login-data');

    if (state !== 'business' && !storedUserData) {
      enqueueSnackbar(staticTexts.somethingWentWrong);
      return;
    }
    if (state === 'business') {
      if (!data.email) {
        setError('email', {
          type: 'manual',
          message: `${staticTexts.email} ${staticTexts.isRequired}`,
        });
        return;
      }
      await resetPassword({
        email: data.email,
        type: UserType.BUSINESS
      });
      enqueueSnackbar(staticTexts.weWillSendResetLink);
    } else {
      const otp = data.otp.join('');
      if (otp.length !== 4) {
        setError('otp', {
          type: 'manual',
          message: staticTexts.otpMustBeOf4digits,
        });
        return;
      }
      sessionStorage.setItem('otp', otp);
    }
  };

  const getHeaderText = () => {
    if (state === 'business') {
      return staticTexts.enterEmailAddressToReceiveResetCode;
    }
    return staticTexts.enterYourRegisteredMobileNumber + ' ' + staticTexts.aResetCode;
  };

  const handleResendOTP = async () => {
    try {
      startLoader();
      const storedUserData = sessionStorage.getItem('login-data');
      if (!storedUserData) {
        enqueueSnackbar(staticTexts.somethingWentWrong);
        return;
      }
      const phone = JSON.parse(storedUserData) || {};
      await resetPassword({
        phone: phone.phone,
        type: UserType.INDIVIDUAL
      });
      enqueueSnackbar(staticTexts.weWillSendAnOTP);
    } catch (error) {
      enqueueSnackbar((error as any)?.response?.data?.message || staticTexts.somethingWentWrong);
    } finally{
      stopLoader();
    }
  };

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <Typography
            component='p'
            fontSize='24px'
            variant='body2'
            className='font-medium'
          >
            {staticTexts.forgotPassword}
          </Typography>
          <Typography
            component='p'
            fontSize='14px'
            className='text-secondary-200 mt-2'
          >
            {getHeaderText()}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className='flex flex-col items-center rounded-md py-2'>
            <Box className='mt-6 sm:justify-center md:self-start'>
              <Controller
                name='otp'
                control={control}
                rules={{
                  validate: (value) => value.every((v) => /^[0-9]$/.test(v)) || 'OTP is required.',
                }}
                render={({ field }) => (
                  <OTPInput
                    otp={field.value}
                    onChange={(index: number, value: string) => {
                      const updatedOtp = [...field.value];
                      updatedOtp[index] = value;
                      field.onChange(updatedOtp);
                    }}
                    error={errors?.otp?.message}
                    onResendOTP={handleResendOTP}
                  />
                )}
              />
              <Typography
                component='p'
                fontSize='12px'
                marginTop='24px'
                textAlign='left'
                className='text-secondary-200 mt-2'
              >
                {staticTexts.weWillSendAnOTP}
              </Typography>
            </Box>

            <Box className='flex gap-4 mt-8 justify-center items-center'>
              <Button
                variant='outlined'
                className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
                onClick={() => navigate(-1)}
                buttonStyle={{
                  color: '#111111D8',
                  border: '1px solid #C9C2B4',
                }}
              >
                {staticTexts.backToLogin}
              </Button>

              <Button
                type='submit'
                className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
              >
                {staticTexts.continue}
              </Button>
            </Box>
          </Box>
        </form>
        <Typography
          variant='body1'
          fontSize='12px'
          color='#776E65'
          marginTop='40px'
          className='text-center cursor-pointer'
        >
          {staticTexts.needHelp}{' '}
          <span
            className='underline'
            onClick={() => navigate('/contact-support')}
          >
            {staticTexts.contactSupport}
          </span>
        </Typography>
      </Box>
    </Fragment>
  );
};

export default ForgotPassword;
