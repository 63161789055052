import axios from 'axios';
import { LocalStorageKeys } from '../../constants/AppConstants';
axios.defaults.responseType = 'json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


/**
 * Make a HTTP request to the specified URL.
 * @param method The HTTP method to use (eg. GET, POST, PUT, DELETE).
 * @param url The URL to make the request to.
 * @param data The data to send with the request (if method is POST or PUT).
 * @param params The query parameters to send with the request (if any).
 * @param headers The headers to send with the request (if any). Defaults to
 *   Content-Type: application/json and Authorization: Bearer <auth-token>.
 *   The Authorization header is set to the value of
 *   localStorage.getItem('auth-token') or localStorage.getItem('impersonate-token'),
 *   or '' if neither is set.
 * @returns A Promise that resolves to the response data if the request is
 *   successful (2xx status code), or rejects with an error if the request
 *   fails (non-2xx status code).
 */
export const httpRequest = async (
  method: string,
  url: string,
  data?: any,
  params?: object,
  headers: any = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem(LocalStorageKeys.IMPERSONATE_TOKEN) || localStorage.getItem(LocalStorageKeys.AUTH_TOKEN) || ''}`
  }
) =>
  new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      params,
      headers
    })
      .then(response => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        reject(err);
      })
  });

export function httpFormRequest<T>(
  url: string,
  file: any = {}
) {
  return new Promise<T>((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('originalname', file.name);
    const config = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem(LocalStorageKeys.AUTH_TOKEN) || ""}`,
        "Content-Type": "multipart/form-data",
        "x-locale": `${localStorage.getItem('locale') || 'en'}`

      },
    };

    axios.post(url, formData, config)
      .then((response) => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        // localStorage.removeItem('token');
        console.log('insdie catch', err);
        reject(err);
      });
  });
}
