import { httpRequest } from '../network';

/**
 * Claims an item as lost by a user. The payload should contain the item's id, the user's id,
 * and the reason for the claim.
 *
 * @param {object} payload - The payload for the claim request.
 * @property {string} item - The id of the item being claimed.
 * @property {string} user - The id of the user who is claiming the item.
 * @property {string} reason - The reason for the claim.
 * @returns {Promise} A promise that resolves with the result of the claim request.
 */
export const claimItem = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/aggregation/claim', payload)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );

/**
 * Accepts a claim request for a lost item. The claim request should already be registered
 * in the system. The payload should contain the id of the user who is claiming the item,
 * and the id of the item being claimed.
 *
 * @param {string} claimUser - The id of the user who is claiming the item.
 * @param {string} itemId - The id of the item being claimed.
 * @returns {Promise} A promise that resolves with the result of the claim request.
 */
export const acceptClaimRequest = (claimUser: string, itemId: string) => 
  new Promise((resolve, reject) =>
    httpRequest('PUT', `/aggregation/item/item-resolved/${claimUser}/${itemId}`)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );

/**
 * Retrieves the details of a claim request for a lost item. The claim request should already
 * be registered in the system. The payload should contain the id of the user who is claiming
 * the item, and the id of the item being claimed.
 *
 * @param {string} _claimer - The id of the user who is claiming the item.
 * @param {string} _item - The id of the item being claimed.
 * @returns {Promise} A promise that resolves with the result of the claim request.
 */
export const getClaimDetailsById = (_claimer: string, _item: string) =>
  new Promise((resolve, reject) =>
    httpRequest('GET', `/aggregation/claim/claim-request-details/${_claimer}/${_item}`)
      .then((response: any) => resolve(response.data))
      .catch(reject)
  );
