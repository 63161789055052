import { httpRequest } from '../network';

/**
 * Send OTP for signup.
 * @param {Object} payload - an object that contains user phone number and country code.
 * @returns {Promise} - a promise that resolves with the response of the signup OTP request.
 */

export const sendSignupOTP = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/send-verification-otp', payload)
      .then(resolve)
      .catch(reject))

/**
 * Verify OTP for signup.
 * @param {Object} payload - an object that contains OTP and other signup information.
 * @returns {Promise} - a promise that resolves with the response of the OTP verification request.
 */
export const verifySignupOTP = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/validate-otp', payload)
      .then(resolve)
      .catch(reject))

/**
 * Signup with the given payload.
 * @param {Object} payload - an object that contains user signup information.
 * @returns {Promise} - a promise that resolves with the response of the signup request.
 */

export const signup = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/signup', payload)
      .then(resolve)
      .catch(reject));

/**
 * Send OTP for login.
 * @param {Object} payload - an object that contains user phone number and country code.
 * @returns {Promise} - a promise that resolves with the response of the login OTP request.
 */

export const login = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/login', payload)
      .then(resolve)
      .catch(reject))

/**
 * Verify OTP for login.
 * @param {Object} payload - an object that contains user phone number, country code, and OTP.
 * @returns {Promise} - a promise that resolves with the response of the verify login OTP request.
 */

export const verifyLoginOTP = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/verify-login-otp', payload)
      .then(resolve)
      .catch(reject)
  )

/**
 * Change the password of the user.
 * @param {Object} payload - an object that contains oldPassword, newPassword, and confirmNewPassword.
 * @returns {Promise} - a promise that resolves with the response of the change password request.
 */

export const changePassword = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/auth/change-password', payload)
      .then(resolve)
      .catch(reject))

/**
 * Reset the password of the user.
 * @param {Object} payload - an object that contains phone number, country code, OTP, newPassword, and confirmNewPassword.
 * @returns {Promise} - a promise that resolves with the response of the reset password request.
 */

export const resetPassword = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/reset-password', payload)
      .then(resolve)
      .catch(reject))

/**
 * Verify OTP for reset password.
 * @param {Object} payload - an object that contains user phone number, country code, and OTP.
 * @returns {Promise} - a promise that resolves with the response of the verify reset password OTP request.
 */

export const verifyResetPasswordOTP = (payload: object) =>
  new Promise((resolve, reject) =>
    httpRequest('POST', '/user/public/verify-reset-password-code', payload)
      .then(resolve)
      .catch(reject))
