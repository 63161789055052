import { LocalStorageKeys } from '../../../constants/AppConstants';
import { UPDATE_LOGIN_STATUS } from '../../actions';

const initialState = {
  isLoggedIn: !!(localStorage.getItem(LocalStorageKeys.IMPERSONATE_TOKEN) || localStorage.getItem(LocalStorageKeys.AUTH_TOKEN)),
};

/**
 * Handles login status state
 * @function
 * @param {object} state - Login status state
 * @param {object} action - Action containing type and payload
 * @returns {object} - New state
 */
export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
