import { httpRequest } from "../network";

/**
 * Retrieves categories from the server.
 *
 * @param {Object} params - an object containing query parameters.
 * @returns {Promise<Object[]>} A promise that resolves with an array of categories.
 */
export const getCategories = async (params: any) => new Promise(async (resolve, reject) => {

  try {
    const categories = await httpRequest('GET', '/aggregation/public/categories', {}, params) as any;
    resolve(categories?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Creates a new item on the server.
 *
 * @param {Object} payload - an object containing the item's details.
 * @returns {Promise<Object>} A promise that resolves with the created item's details.
 */
export const createItem = async (payload: any) => new Promise(async (resolve, reject) => {

  try {
    const item = await httpRequest('POST', '/aggregation/item', payload) as any;
    resolve(item?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Retrieves the items of the logged in user from the server.
 *
 * @param {Object} [params] - an object containing query parameters.
 * @param {Object} [data] - an object containing data to be sent in the request body.
 * @returns {Promise<Object[]>} A promise that resolves with an array of items.
 */
export const getItems = async (params?: any, data?:any) => new Promise(async (resolve, reject) => {
  try {
    const items = await httpRequest('POST', '/aggregation/item/my-items', params, data) as any;
    resolve(items?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Updates an existing item on the server.
 *
 * @param {string} itemId - The ID of the item to be updated.
 * @param {Object} payload - An object containing the updated item's details.
 * @returns {Promise<Object>} A promise that resolves with the updated item's details.
 */
export const updateItem = async (itemId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const item = await httpRequest('PUT', `/aggregation/item/${itemId}`, payload) as any;
    resolve(item?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Deletes multiple items from the server.
 *
 * @param {Array<string>} itemIds - An array containing the IDs of the items to be deleted.
 * @returns {Promise<Object>} A promise that resolves with the details of the deleted items.
 */

export const deleteItem = async (itemIds: Array<string>) => new Promise(async (resolve, reject) => {
  try {
    const item = await httpRequest('DELETE', '/aggregation/item', { itemIds }) as any;
    resolve(item?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Resolves a claim request for a lost item that was registered by the same user.
 * Sends a PUT request to the server to update the item's status.
 *
 * @param {string} itemId - The ID of the item to be resolved.
 * @returns {Promise<Object>} A promise that resolves with the updated item's details.
 */
export const resolveItemSelf = (itemId: string) => new Promise(async (resolve, reject) => {
  try {
    const item = await httpRequest('PUT', `/aggregation/item/item-resolved-self/${itemId}`) as any;
    resolve(item?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Retrieves the list of claim requests for the logged in user.
 * Sends a GET request to the server to fetch the list of claim requests.
 *
 * @param {Object} params - an object containing query parameters.
 * @param {number} params.page - the page number to retrieve.
 * @param {number} params.limit - the number of claim requests to retrieve per page.
 * @param {string} params.status - the status of the claim request to filter by.
 * @param {string} params.text - the text to search for in the claim request's item name.
 * @returns {Promise<Object[]>} A promise that resolves with an array of claim requests.
 */
export const getMyClaims = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const claims = await httpRequest('GET', `/aggregation/claim/my-claim-requests?page=${params.page}&limit=${params.limit}&status=${params.status || ''}&text=${params.text || ''}`) as any;
    resolve(claims?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Retrieves the list of claim requests from the server.
 *
 * @param {Object} params - an object containing query parameters.
 * @param {number} params.page - the page number to retrieve.
 * @param {number} params.limit - the number of claim requests to retrieve per page.
 * @param {string} params.status - the status of the claim request to filter by.
 * @param {string} params.text - the text to search for in the claim request's item name.
 * @returns {Promise<Object[]>} A promise that resolves with an array of claim requests.
 */
export const getClaimRequests = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const claims = await httpRequest('GET', `/aggregation/claim/claim-request?page=${params.page}&limit=${params.limit}&status=${params.status || ''}&text=${params.text || ''}`) as any;
    resolve(claims?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Retrieves item analytics from the server.
 *
 * @returns {Promise<Object>} A promise that resolves with an object containing item analytics.
 */
export const getItemAnalytics = async () => new Promise(async (resolve, reject) => {
  try {
    const analytics = await httpRequest('GET', '/aggregation/item/item-analytics') as any;
    resolve(analytics?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Rejects a claim request for a lost item.
 *
 * @param {string} claimedUserId - the id of the user who is claiming the item.
 * @param {string} itemId - the id of the item being claimed.
 * @returns {Promise<Object>} A promise that resolves with an object containing the result of the claim request.
 */
export const rejectClaimRequest = async (claimedUserId: string, itemId: string) => new Promise(async (resolve, reject) => {
  try {
    const claim = await httpRequest('PUT', `/aggregation/claim/reject-claim-request/${claimedUserId}/${itemId}`) as any;
    resolve(claim?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Retrieves a questionnaire based on the specified type and category ID.
 *
 * @param {string} type - The type of questionnaire to retrieve (e.g., 'LOST', 'FOUND', 'CLAIM').
 * @param {string} categoryId - The ID of the category for which to retrieve the questionnaire.
 * @returns {Promise<Object>} A promise that resolves with the questionnaire data.
 */

export const getQuestionnaire = async (type: string, categoryId: string) => new Promise(async (resolve, reject) => {
  try {
    const questionnaire = await httpRequest('GET', `/aggregation/public/questions?type=${type}&categoryId=${categoryId}`) as any;
    resolve(questionnaire?.data);
  } catch (error) {
    reject(error);
  }
});

/**
 * Checks if a user has already claimed an item.
 *
 * @param {string} itemId - The ID of the item to check.
 * @returns {Promise<boolean>} A promise that resolves with a boolean indicating whether the user has claimed the item.
 */
export const checkClaimedItem = async (itemId: string) => new Promise(async (resolve, reject) => {
  try {
    const response = await httpRequest('GET', `/aggregation/claim/check-user-claim-item/${itemId}`) as any;
    resolve(response?.data);
  } catch (error) {
    reject(error);
  }
});