import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';

// TODO - Mayank: Add comments
// TODO - Mayank: Remove unused imports

export const ClaimInstruction = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  return (
    <Fragment>
      <Box
        className='bg-[#FFF7E6] rounded-lg justify-start w-full p-6'
        sx={{
          width: '100%',
          p: 2,
          order: { xs: 3, md: 1 },
        }}
      >
        <Typography
          fontSize='16px'
          color='#4A403A'
          fontWeight={500}
        >
          {staticTexts.claimInstructions}
        </Typography>

        <Typography
          className=' text-secondary-200'
          component='p'
          marginTop='12px'
          fontSize='14px'
        >
          1. {staticTexts.pleaseProvideDetailedInfo}
        </Typography>

        <Typography
          className=' text-secondary-200'
          component='p'
          marginTop='12px'
          fontSize='14px'
        >
          2. {staticTexts.verifyOwnerShip}
        </Typography>

        <Typography
          className=' text-secondary-200'
          component='p'
          marginTop='12px'
          fontSize='14px'
        >
          3. {staticTexts.waitForConfirmation}
        </Typography>
      </Box>
    </Fragment>
  );
};
