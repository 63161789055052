import React, { Fragment, useMemo, useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  SwipeableDrawer,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import moment from 'moment';

const FilterDrawer = ({
  onApply,
  isOpen,
  toggleDrawer,
}: {
  onApply: (filters: any) => void;
  isOpen: boolean;
  toggleDrawer: (state: boolean) => void;
}) => {
  const [expandedViewMode, setExpandedViewMode] = useState(false);
  const [expandedDateRange, setExpandedDateRange] = useState(false);
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);

  const [filters, setFilters] = useState({
    sortBy: 'newest',
    viewMode: 'grid',
    dateRange: 'today',
    customDate: { from: '', to: '' },
  });

  const handleClearAll = () => {
    setFilters({
      sortBy: 'newest',
      viewMode: 'grid',
      dateRange: 'today',
      customDate: { from: '', to: '' },
    });
    onApply(filters)
  };

  const handleViewModeToggle = () => {
    setExpandedViewMode(!expandedViewMode);
  };

  const handleDateRangeToggle = () => {
    setExpandedDateRange(!expandedDateRange);
  };

  const Puller = styled('div')(({ theme }) => ({
    width: 36,
    height: 6,
    backgroundColor: '#3C3C434D',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    ...theme.applyStyles('dark', {
      backgroundColor: '#3C3C434D',
    }),
  }));

  return (
    <Fragment>
      <SwipeableDrawer
        anchor='bottom'
        sx={{ '& .MuiDrawer-paper': { height: '90%', borderRadius: '12px 12px 0px 0px', bgcolor: '#FFF7E6' } }}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(false)}
      >
        <Box sx={{ width: '100%', padding: 2 }}>
          {/* Header */}
          <Puller />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant='h6'
              color='#4A403A'
              fontSize='20px'
              textAlign='left'
            >
              {staticTexts.sortAndFilter}
            </Typography>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>

            {/* Clear All Filter Button */}
            <Button
              buttonStyle={{
                border: '1px solid #C9C2B4',
                color: '#776E65',
                width: '48%',
              }}
              onClick={()=>{handleClearAll()}}
              variant='outlined'
            >
              {staticTexts.clearAll}
            </Button>

            {/* Apply Filter Button */}
            <Button
              buttonStyle={{
                color: '#FFF7E6',
                width: '48%',
              }}
              onClick={() => {
                onApply(filters);
              }}
              variant='contained'
              color='primary'
            >
              {staticTexts.apply}
            </Button>
          </Box>
          <Divider />


          {/* Sort By Section */}
          <Box sx={{ mt: 2 }}>

            {/* Sort By Text */}
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='18px'
              color='#4A403A'
              fontWeight={400}
              className='!mb-3'
            >
              {staticTexts.sortBy}
            </Typography>

            {/* Sort By Options */}
            <Select
              fullWidth
              value={filters.sortBy}
              onChange={(e) => setFilters({ ...filters, sortBy: e.target.value })}
            >
              <MenuItem value='newest'>{staticTexts.newestToOldest}</MenuItem>
              <MenuItem value='oldest'>{staticTexts.oldestToNewest}</MenuItem>
              <MenuItem value='A-Z'>{staticTexts.alphabeticalAtoZ}</MenuItem>
              <MenuItem value='Z-A'>{staticTexts.alphabeticalZtoA}</MenuItem>
            </Select>
          </Box>


          {/* Filter By Text */}
          <Typography
            variant='subtitle1'
            sx={{ my: 2 }}
            component='p'
            fontSize='18px'
            color='#4A403A'
            fontWeight={400}
          >
            {staticTexts.filterBy}
          </Typography>

          {/* View Mode Filter */}
          <Accordion
            expanded={expandedViewMode}
            onChange={handleViewModeToggle}
          >
            <AccordionSummary
              expandIcon={
                expandedViewMode ? (
                  <IconButton size='small'>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton size='small'>
                    <Typography variant='h6'>+</Typography>
                  </IconButton>
                )
              }
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography>{staticTexts.viewMode}</Typography>
                {/* <IconButton
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilters({ ...filters, viewMode: 'list' }); // Clear view mode
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={filters.viewMode}
                onChange={(e) => setFilters({ ...filters, viewMode: e.target.value })}
              >
                {[
                  { value: 'list', label: staticTexts.listView },
                  { value: 'grid', label: staticTexts.gridView },
                ].map((item) => (
                  <Box
                    key={item.value}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    // onClick={() => setFilters({ ...filters, viewMode: item.value })}
                  >
                    <Box
                      component='span'
                      sx={{ flexGrow: 1, textAlign: 'left' }}
                    >
                      {item.label}
                    </Box>
                    <Radio
                      value={item.value}
                      checked={filters.viewMode.toLowerCase() === item.value.toLowerCase()}
                      onChange={(e) => setFilters({ ...filters, viewMode: e.target.value })}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>

          {/* Date Range */}
          <Accordion
            expanded={expandedDateRange}
            onChange={handleDateRangeToggle}
          >
            <AccordionSummary
              expandIcon={
                expandedDateRange ? (
                  <IconButton size='small'>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton size='small'>
                    <Typography variant='h6'>+</Typography>
                  </IconButton>
                )
              }
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography>{staticTexts.time}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={JSON.stringify(filters.dateRange)}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    dateRange: e.target.value === 'custom' ? 'custom' : JSON.parse(e.target.value),
                  })
                }
              >
                {[
                  {
                    label: staticTexts.today,
                    value: {
                      from: moment().startOf('day').toISOString(),
                      to: moment().endOf('day').toISOString(),
                    },
                  },
                  {
                    label: staticTexts.yesterday,
                    value: {
                      from: moment().subtract(1, 'day').startOf('day').toISOString(),
                      to: moment().subtract(1, 'day').endOf('day').toISOString(),
                    },
                  },
                  {
                    label: staticTexts.lastWeek,
                    value: {
                      from: moment().subtract(1, 'week').startOf('week').toISOString(),
                      to: moment().subtract(1, 'week').endOf('week').toISOString(),
                    },
                  },
                  {
                    label: staticTexts.lastMonth,
                    value: {
                      from: moment().subtract(1, 'month').startOf('month').toISOString(),
                      to: moment().subtract(1, 'month').endOf('month').toISOString(),
                    },
                  },
                  {
                    label: staticTexts.lastYear,
                    value: {
                      from: moment().subtract(1, 'year').startOf('year').toISOString(),
                      to: moment().subtract(1, 'year').endOf('year').toISOString(),
                    },
                  },
                  {
                    label: staticTexts.customDate,
                    value: 'custom',
                  },
                ].map((item) => (
                  <Box
                    key={item.label}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Box
                      component='span'
                      sx={{ flexGrow: 1, textAlign: 'left' }}
                    >
                      {item.label}
                    </Box>
                    <Radio
                      value={typeof item.value === 'string' ? item.value : JSON.stringify(item.value)}
                      checked={JSON.stringify(filters.dateRange) === JSON.stringify(item.value)}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          dateRange: e.target.value === 'custom' ? 'custom' : JSON.parse(e.target.value),
                        });
                      }}
                    />
                  </Box>
                ))}
              </RadioGroup>

              {/* Custom Date Range */}
              {filters.dateRange === 'custom' && (
                <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                  <TextField
                    type='date'
                    label='From'
                    slotProps={{ inputLabel: { shrink: true } }}
                    value={filters.customDate.from}
                    onChange={(e) =>
                      setFilters({ ...filters, customDate: { ...filters.customDate, from: e.target.value } })
                    }
                    fullWidth
                  />
                  <TextField
                    type='date'
                    label='To'
                    slotProps={{ inputLabel: { shrink: true } }}
                    value={filters.customDate.to}
                    onChange={(e) =>
                      setFilters({ ...filters, customDate: { ...filters.customDate, to: e.target.value } })
                    }
                    fullWidth
                  />
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};

export default FilterDrawer;
