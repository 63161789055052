import React, { Fragment } from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Rating as MuiRating, Typography } from '@mui/material';
import { Button } from '../../Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';

export const Rating: React.FC = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <Fragment>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        marginBottom='32px'
      >
        <MuiRating
          value={4.5}
          sx={{
            fontSize: '20px !important',
          }}
          precision={0.5}
          readOnly
        />
      </Box>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {/* Left Stack of Images */}
        <Box
          display='flex'
          alignItems='center'
        >
          <Avatar
            alt='Image 1'
            src='https://mui.com/static/images/avatar/1.jpg'
            sx={{ width: 48, height: 48, mr: -1, border: '2px solid #FFF7E6' }}
          />
          <Avatar
            alt='Image 2'
            src='https://mui.com/static/images/avatar/2.jpg'
            sx={{ width: 64, height: 64, mr: -1, border: '2px solid #FFF7E6' }}
          />
        </Box>

        {/* Center Avatar */}
        <Avatar
          alt='Main Image'
          src='https://mui.com/static/images/avatar/5.jpg'
          sx={{ width: 100, height: 100, zIndex: 2, border: '2px solid #FFF7E6' }}
        />

        {/* Right Stack of Images */}
        <Box
          display='flex'
          alignItems='center'
        >
          <Avatar
            alt='Image 3'
            src='https://mui.com/static/images/avatar/4.jpg'
            sx={{ width: 64, height: 64, ml: -1, zIndex: 1, border: '2px solid #FFF7E6' }}
          />
          <Avatar
            alt='Image 4'
            src='https://mui.com/static/images/avatar/2.jpg'
            sx={{ width: 48, height: 48, ml: -1, border: '2px solid #FFF7E6' }}
          />
        </Box>
      </Box>
      <Box
        className='mt-8'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        textAlign='center'
        padding='8px'
      >
        <Typography
          component='p'
          className='mt-8 w-full text-2xl leading-8 font-medium tracking-tight text-center'
          color='#4A403A'
          fontSize='26px'
        >
          {staticTexts.ourCustomersRateOurService}
        </Typography>

        <Typography
          component='p'
          className='w-2/3 text-2xl leading-8 font-medium tracking-tight text-center'
          color='#655C57'
          fontSize='16px'
          maxWidth='480px'
          marginTop='12px'
        >
          {staticTexts.iWasHappyWhenIFoundMyItem}
        </Typography>

        <Typography
          color='#4A403A'
          fontSize='26px'
          maxWidth='648px'
          letterSpacing='0.01px'
          textAlign='center'
          marginTop='128px'
          marginBottom='40px'
        >
          {staticTexts.registerYourValuableWithLqeet}
        </Typography>

        <Button
          buttonStyle={{ background: '#FF6F61' }}
          onClick={() => navigate('/my-items')}
        >
          {staticTexts.registerYourValuable}
        </Button>
      </Box>
    </Fragment>
  );
};
