import { FETCHING_CHATS, SCROLL_TO_BOTTOM } from '../../actions';

const initialState = {
  scrollToBottom: false,
  fetchingChats: false,
};

export const chatWindowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SCROLL_TO_BOTTOM:
      return {
        ...state,
        scrollToBottom: action.payload,
      };

    case FETCHING_CHATS:
      return {
        ...state,
        fetchingChats: action.payload,
      };
    default:
      return state;
  }
};

export default chatWindowReducer;