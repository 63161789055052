import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer, Header } from '../../components';


/**
 * Layout component that serves as the base layout for the application.
 * It renders the Header, Footer, and any child components specified
 * by the Outlet. The Footer is conditionally rendered based on the
 * current pathname, and it is excluded on the 'chat' page.
 *
 * @returns {JSX.Element} The rendered layout component.
 */

export const Layout = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Header />
      <div className='min-w-96'>
        <Outlet />
      </div>
      {!['chat'].includes(pathname.split('/').pop() || '') && <Footer />}
    </>
  );
};
