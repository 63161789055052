import { io } from 'socket.io-client';
import STORE from '../../redux/store';
import { NEW_MESSAGE, SET_NOTIFICATION_COUNT } from '../../redux';
import { showNotification } from '../notification';
import { defaultProfileImg, getStaticTexts } from '../../assets';

export let socket: any;

const language = STORE.getState().language;
const staticTexts = getStaticTexts(language);
/**
 * Creates a socket connection to the server
 *
 * @param {string} token - The access token of the user
 *
 * @returns {Promise<void>}
 */
export const createSocketConnection = async (token: string) => {
  try {
    socket = io(`${process.env.REACT_APP_API_URL}`, {
      query: {
        host: window.location.hostname,
        url: window.location.href,
      },
      extraHeaders: {
        token,
      },
      reconnectionAttempts: 15,
      reconnectionDelayMax: 5000,
    });

    socket.on('connected', () => {});

    socket.on('message', (data: any) => {
      console.log('message send');
    });

    socket.on('receive-message', (data: any) => {
      const profile = STORE.getState().profile;
      const activeChat = STORE.getState().messages || [];
      const activeChatId = activeChat.find((chat: { _receiver: string; _item: string }) => {
        return chat._receiver === (profile as any)?._id && chat._item === data._item;
      });
     
      if (activeChatId) {
        STORE.dispatch({
          type: NEW_MESSAGE,
          payload: {
            _sender: data._sender,
            _receiver: (profile as any)?._id,
            _item: data._item,
            isRead: false,
            value:
              data.type === 'IMAGE'
                ? {
                    url: data.message.url,
                    text: data.message.text,
                  }
                : data.message,
            type: data.type,
            status: 'ACTIVE',
          },
        });
      }
    });

    socket.on('new-claim-request-notification', (data: any) => {
      const { senderName, senderProfile } = data || {};
      showNotification(
        staticTexts.claimRequest,
        `${staticTexts.newClaimRequestFrom} ${senderName}`,
        senderProfile || defaultProfileImg
      );
    });

    socket.on('new-chat-message-notification', (data: any) => {
      const { senderName, senderProfile, message } = data || {};
      showNotification(`${staticTexts.newMessageFrom} ${senderName}`, message, senderProfile || defaultProfileImg);
    });

    socket.on('unread-notification-count', (data: any) =>{
      STORE.dispatch({
        type: SET_NOTIFICATION_COUNT,
        payload: data,
      })
    })

    socket.on('error', console.log('error in connecting socket'));

    socket.on('disconnect', function () {});
  } catch (error) {
    console.log(error, 'Error in socket connection');
  }
};

/**
 * Emits a socket event with given payload.
 * @param {string} event - the event name to be emitted
 * @param {any} payload - the payload to be sent with the event
 */
export const emit = (event: any, payload: any) => {
  if (socket && socket.connected) {
    socket.emit(event, payload);
  }
};
