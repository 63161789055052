import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Avatar, Box, Button, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { getStaticTexts, ProfileIcon } from '../../../assets';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_MESSAGE, SCROLL_TO_BOTTOM, UPDATE_CHAT_LIST, UPDATE_LAST_MESSAGE } from '../../../redux';
import { emit } from '../../../services/socket';
import { upload } from '../../../services/common';
import DateTimeFormatting from '../../../utils/extensions/DateTimeFormatting';
import CircularProgress from '@mui/material/CircularProgress';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Define interfaces in model directory not in the component itself

interface ChatWindowProps {
  user?: any;
  onBack: () => void;
  className?: string;
  username?: string;
  messages: Array<any>;
  onLoadMore: () => void;
  hasMore: boolean;
  userProfile?: string
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  user,
  onBack,
  className,
  username,
  messages,
  onLoadMore,
  hasMore,
  userProfile
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [message, setMessage] = useState('');
  const profile = useSelector((state: any) => state.profile);
  const [image, setImage] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const { scrollToBottom, fetchingChats } = useSelector((state: any) => state.chatWindow);
  const dispatch = useDispatch();

  // Helper function to format the date and group messages
  const groupMessagesByDate = (messages: any[]) => {
    const groupedMessages: { [key: string]: any[] } = {};

    messages.forEach((msg) => {
      const date = msg.createdAt || msg.timestamp ? new Date(msg.createdAt || msg.timestamp) : new Date();
      const formattedDate = getFormattedDate(date);

      if (!groupedMessages[formattedDate]) {
        groupedMessages[formattedDate] = [];
      }
      groupedMessages[formattedDate].push(msg);
    });

    return groupedMessages;
  };

  const getFormattedDate = (date: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) return 'Today';
    if (isYesterday) return 'Yesterday';

    // If it's not today or yesterday, return the formatted date
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (scrollToBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (!messagesContainerRef.current || !hasMore) return;

      const scrollTop = messagesContainerRef.current.scrollTop;
      // Check if the user is at the top of the container
      if (scrollTop === 0 && messages.length >= 10) {
        onLoadMore();
      }
    };

    const container = messagesContainerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => container?.removeEventListener('scroll', handleScroll);
  }, [onLoadMore, hasMore]);

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim() || image) {
      const newMessage = {
        _sender: profile._id,
        _receiver: user._receiver,
        _item: user._item?._id || user?._item,
        isRead: false,
        value: image
          ? {
              text: message.trim() || '',
              url: image,
            }
          : message.trim(),
        type: image ? 'IMAGE' : 'TEXT',
        status: 'ACTIVE',
      };

      emit('message', {
        _sender: profile._id,
        receiverId: user._receiver,
        itemId: user._item?._id || user?._item,
        message: image
          ? {
              text: message.trim() || '',
              url: image,
            }
          : message.trim(),
        type: image ? 'IMAGE' : 'TEXT',
      });

      dispatch({
        type: NEW_MESSAGE,
        payload: newMessage,
      });

      dispatch({
        type: UPDATE_LAST_MESSAGE,
        payload : {
          receiverId: user._receiver,
          itemId: user._item?._id || user?._item,
          message: image
            ? {
                text: message.trim() || '',
                url: image,
              }
            : message.trim(),
        }
      })
      setMessage('');
      setImage(null);
      dispatch({
        type: SCROLL_TO_BOTTOM,
        payload: true,
      });
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const url = await upload(file);
      setImage(url);
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <div className={`${className} flex flex-col`}>
      {/* Header */}
      <AppBar
        position='static'
        sx={{
          backgroundColor: '#FFF7E6',
          boxShadow: 'none',
          color: '#4A403A',
          borderRadius: '8px',
        }}
      >
        <Toolbar>
          <IconButton
            className='md:!hidden sm:block'
            style={{ color: '#FF6F61' }}
            onClick={onBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Avatar
            className='me-3'
            src={userProfile || ProfileIcon}
            alt='user-profile-img'
          />
          <Typography
            variant='h6'
            className='ml-2'
            fontSize='16px'
          >
            {user?.receiverName || username || staticTexts.lqeetUser}
          </Typography>
        </Toolbar>
        <Divider />
      </AppBar>

      {/* Messages */}
      <div
        className='flex-1 p-4 overflow-y-auto'
        ref={messagesContainerRef}
      >
        {fetchingChats ? (
          <div className='flex justify-center items-center w-full'>
          <CircularProgress />
          </div>
        ) : (
          Object.keys(groupedMessages).map((date, index) => (
            <div key={index}>
              <Box className='text-center my-4'>
                <Typography
                  variant='body2'
                  color='#655B53FA'
                  borderRadius='4px'
                  fontSize='12px'
                  padding='4px 6px'
                >
                  {date}
                </Typography>
              </Box>
              {groupedMessages[date].map((msg: any, index: number) => (
                <div
                  key={index}
                  className={`mb-4 ${msg._sender === profile._id ? 'text-right' : 'text-left'}`}
                >
                  <div
                    className={`${msg._sender === profile._id ? 'bg-[#FFE0B5]' : 'bg-[#ECE4D5]'} ${
                      msg._sender === profile._id ? 'ltr:ms-24 rtl:me-24' : 'ltr:me-24 rtl:ms-24'
                    } px-3 py-2 rounded-lg inline-block text-[#4A403A] text-sm ltr:text-left rtl:text-right`}
                  >
                    {msg.type === 'IMAGE' && (
                      <div className='mb-2'>
                        <img
                          src={msg.value?.url}
                          alt='uploaded'
                          className='max-w-[150px] max-h-[150px] object-cover rounded-lg'
                        />
                      </div>
                    )}
                    {msg.type === 'IMAGE' ? msg.value?.text : msg.value}
                  </div>
                  <div className='block mt-1 text-[#655B53FA] text-[10px] px-3'>
                    {DateTimeFormatting.getDateByFormatCustomUTC(
                      msg.createdAt ?? msg.timestamp ?? '',
                      true,
                      DateTimeFormatting.DateTimeFormat.hh_mm_a
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))
        )}

        <div
          ref={messagesEndRef}
        ></div>
      </div>

      {/* Input */}
      <div className='p-1 border rounded-lg m-2 border-[#ECE4D5]'>
        <form
          onSubmit={handleSendMessage}
          className='flex gap-2 items-center'
        >
          {/* Image Upload */}
          <input
            accept='image/*'
            id='upload-image'
            type='file'
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <label
            htmlFor='upload-image'
            className='mt-auto'
          >
            <IconButton
              style={{ color: '#776E65', padding: '8px' }}
              component='span'
            >
              <AttachFileIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </label>
          {/* Text Input */}
          <div className='w-full'>
            <input
              type='text'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Message here'
              className={`focus:outline-none bg-transparent w-5/6 custom-placeholder ${image && 'pt-1'}`}
            />
            {/* Preview Uploaded Image */}
            {image && (
              <div className='mt-2 w-fit relative flex items-center gap-2 group'>
                <img
                  src={image}
                  alt='Preview'
                  className='w-16 h-16 object-cover rounded-md'
                />
                <div className='absolute w-16 h-16 rounded-md inset-0 bg-black bg-opacity-60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity'>
                  <DeleteIcon
                    className='text-white cursor-pointer'
                    onClick={handleDeleteImage}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Send Button */}
          <Button
            variant='contained'
            type='submit'
            color='primary'
            className='p-1 rounded-lg max-h-9 self-end'
          >
            <SendIcon sx={{ color: '#FFF7E6', fontSize: '18px', marginInlineEnd: '4px' }} />
            {staticTexts.send}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;
