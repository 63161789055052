import { SET_NOTIFICATION_COUNT } from '../../actions';

const initialState = {
  count: 0,
};

export const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_NOTIFICATION_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
