import { Search } from '@mui/icons-material';
import React, { forwardRef } from 'react';
import STORE from '../../redux/store/index';
import { getStaticTexts } from '../../assets';

const language = STORE.getState().language;
const staticTexts = getStaticTexts(language)

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholderText?: string;
  className?: string;
  containerStyle?: React.CSSProperties;
  onSearch?: (value: string) => void;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ placeholderText = staticTexts.search, className = '', containerStyle, onSearch, ...inputProps }, ref) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onSearch) {
        onSearch((event.target as HTMLInputElement).value);
      }
    };

    return (
      <div
        className={`relative rounded-lg border border-[#C9C2B4] ${className}`}
        style={containerStyle}
      >
        <input
          type='text'
          placeholder={placeholderText}
          ref={ref}
          className='w-full ps-10 pr-4 py-2 rounded-lg border-none outline-none bg-[#FFF7E6] custom-placeholder'
          onKeyDown={handleKeyDown}
          {...inputProps}
        />
        <span className='absolute left-3 top-1/2 transform -translate-y-1/2 text-[#776E65]'>
          <Search />
        </span>
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';
