import React, { memo } from "react";
import { Typography, Grid2 as Grid, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../../assets";
import { arLqeetLogoForFooter, lqeetLogoForFooter } from "../../../assets";
import {
  Container,
  Content,
  FooterWrapper,
  LinkList,
  Logo,
  SectionTitle,
} from "./Footer.styles";
import { LocalStorageKeys, UserType } from "../../../constants/AppConstants";

/**
 * Author: Mayank
 * Date: 17 Dec 2024
 * Description:
 * Footer component that renders the footer section of the application.
 * It displays dynamic content based on the current language and
 * location, including the company logo, an about section, and
 * categorized quick links.
 *
 * The footer is not rendered on authentication-related pages.
 *
 * @returns {JSX.Element | null} The rendered footer component or null
 * if the current page is an auth page.
 */

export const Footer: React.FC = memo(() => {
  const { pathname } = useLocation();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const userType =
    localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE) ||
    localStorage.getItem(LocalStorageKeys.USER_TYPE);
  const logo = language === "ar" ? arLqeetLogoForFooter : lqeetLogoForFooter;

  if (pathname.includes("auth")) return null;

  const sections = [
    {
      title: staticTexts.quickLinks,
      links: [
        { label: staticTexts.about, href: "/about" },
        { label: staticTexts.help, href: "/help" },
        { label: staticTexts.termsAndCondition, href: "/terms-and-condition" },
        { label: staticTexts.privacyPolicy, href: "/privacy-policy" },
      ],
    },
    {
      title: staticTexts.lqeetServices,
      links: [
        { label: staticTexts.reportFoundItem, href: "/report-found-item" },
        userType?.toUpperCase() === UserType.INDIVIDUAL
          ? { label: staticTexts.reportLostItem, href: "/report-lost-item" }
          : null,
      ],
    },
  ];

  return (
    <Container>
      <Content>
        <FooterWrapper>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid size={{ xs: 12, md: 4, sm: 4 }}>
              <Logo src={logo} alt="logo" />
              <Typography variant="body1" color="textSecondary">
                {staticTexts.aboutLqeet}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ marginTop: "1rem", display: "block" }}
              >
                © 2024 Lqeet. B.V.
              </Typography>
            </Grid>

            {sections.map((section, index) => (
              <Grid key={index} size={{ xs: 12, md: 2, sm: 6 }}>
                <SectionTitle variant="body2">{section.title}</SectionTitle>
                <LinkList>
                  {section.links.map((link, i) => (
                    <li key={i} className="linkItem">
                      <Link
                        href={link?.href}
                        color="text.secondary"
                        underline="none"
                      >
                        {link?.label}
                      </Link>
                    </li>
                  ))}
                </LinkList>
              </Grid>
            ))}
          </Grid>
        </FooterWrapper>
      </Content>
    </Container>
  );
});
