import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { Box, Avatar, TextField, Typography, MenuItem, IconButton } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useDispatch, useSelector } from 'react-redux';
import { defaultProfileImg, getStaticTexts } from '../../../assets';
import { Button, UploadBox, useSnackbar } from '../../../components';
import { useForm, Controller } from 'react-hook-form';
import { updateProfile, upload } from '../../../services/common';
import { startLoader, stopLoader } from '../../../utils';
import CustomButton from '../../../components/Button/CustomButton';
import { AppButtonVariant, LocalStorageKeys } from '../../../constants/AppConstants';
import { UPDATE_PROFILE_DETAILS } from '../../../redux';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Remove unused imports
// TODO - Mayank: Define interfaces in model directory not in the component itself
// TODO - Mayank: Remove consoles

export const BusinessProfile = () => {
  const [coverPhoto, setCoverPhoto] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const language = useSelector((state: any) => state.language);
  const user = useSelector((state: any) => state.profile);
  const staticTexts = getStaticTexts(language);
  const [coverProfileImageUrl, setCoverProfileImageUrl] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      address: '',
      email: '',
      preferredLanguage: '',
      profile: '',
      coverProfile: '',
      description: ''
    },
  });

  const enqueueSnackbar = useSnackbar();

  /**
     * API Call: Update Profile
     */
  const onSubmit = async (data: any) => {
    try {

      // Show Loading
      startLoader()

      // Prepare Request Payload
      const payload = {
        name: getValues('name'),
        address: getValues('address'),
        email: getValues('email'),
        defaultLanguage: getValues('preferredLanguage'),
        profile: getValues('profile'),
        coverProfile: getValues('coverProfile'),
        description: getValues('description')
      };

      // API Call Update Profile
      await updateProfile(payload);
      dispatch({
        type: UPDATE_PROFILE_DETAILS,
        payload
      })

      // Show Message
      enqueueSnackbar(staticTexts.profileUpdatedSuccessfully);
    } catch (error: any) {
      if (error.response.status === 409) {
        localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
        localStorage.removeItem(LocalStorageKeys.USER_TYPE);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_TOKEN);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_USER_TYPE);
      }

      // Show Message
      enqueueSnackbar(
        error.response?.data?.message ||
        error.message ||
        staticTexts.somethingWentWrong
      );
    } finally {

      // Dismiss Loading
      stopLoader()
    }
  };

  // Function to handle cover photo upload
  const handleCoverPhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    uploadImage(event, 'coverProfile');
  };

  useEffect(() => {
    setProfileData()
  }, [user]);

  const handleClickCoverPhotoButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    fileInputRef.current?.click();
  };

  // Function to handle profile photo upload
  const handleProfilePhotoChange = (image: any) => {
    setValue('profile', image);
    setProfileImageUrl(image);
  };

  const handleChange = (field: any, value: any) => {
    setValue(field, value);
  };

  /**
   * Handle Submit Button Click
   */
  const onSubmitClick = () => {
    if (isEdit) {
      setIsEdit(!isEdit);
      handleSubmit(onSubmit)()
    } else {
      setIsEdit(!isEdit);
    }
  }

  /**
   * Handle Cancel Button Click
   */
  const onCancelClick = () => {
    // Disable Edit Mode
    setIsEdit(!isEdit);

    // Reset the form fields
    setProfileData();

  };

  /**
     * Set Profile Data in the Fields
     */
  const setProfileData = () => {
    // Reset the form to initial data
    setValue('name', user?.name || '');
    setValue('address', user?.address || '');
    setValue('email', user?.email || '');
    setValue('preferredLanguage', user?.defaultLanguage || '');
    setValue('profile', user?.profile || '');
    setValue('coverProfile', user?.coverProfile || '');
    setValue('description', user?.description || '');
    setCoverPhoto('');
    setProfilePhoto('');
    setCoverProfileImageUrl(user?.coverProfile || '');
    setProfileImageUrl(user?.profile || '');
  }


  /**
   * API CallUpload Cover Image
   */
  const uploadImage = async (e: ChangeEvent<HTMLInputElement> | any, type: 'profile' | 'coverProfile') => {
    try {
      // Prepare File to Upload
      const files = e.target?.files;
      const maxSize = 5 * 1024 * 1024;
      const maxDimension = 1024;

      // Show File Selection Error
      if (!files || !files[0]) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile);
        return;
      }

      // Show File Size Error
      const fileSize = files[0].size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan5MB);
        return;
      }

      // Create preview URL and set it immediately for UI update
      const previewUrl = URL.createObjectURL(files[0]);
      setCoverPhoto(previewUrl);
      setCoverProfileImageUrl(previewUrl);

      // Check image dimensions
      const img = new Image();
      const objectUrl = URL.createObjectURL(files[0]);

      img.onload = async () => {
        URL.revokeObjectURL(objectUrl);

        try {
          let fileToUpload: File | Blob = files[0];

          if (img.width > maxDimension || img.height > maxDimension) {
            // Create canvas to resize image
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxDimension) {
                height = Math.round((height * maxDimension) / width);
                width = maxDimension;
              }
            } else {
              if (height > maxDimension) {
                width = Math.round((width * maxDimension) / height);
                height = maxDimension;
              }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);

            // Get blob from canvas
            return new Promise((resolve) => {
              canvas.toBlob(
                async (blob) => {
                  if (blob) {
                    fileToUpload = blob;
                    const profileUrl = await upload(fileToUpload);
                    if (profileUrl) {
                      setCoverProfileImageUrl(profileUrl);
                      setValue('coverProfile', profileUrl);
                    }
                  }
                },
                'image/jpeg',
                0.9
              );
            });
          } else {
            // Show Loading
            startLoader();

            // API Call: Upload Image
            const profileUrl = await upload(fileToUpload);
            if (profileUrl) {
              setCoverProfileImageUrl(profileUrl);
              setValue('coverProfile', profileUrl);
            }
          }
        } catch (error) {
          console.error('Error processing image:', error);

          // Show Message
          enqueueSnackbar(staticTexts.somethingWentWrong);
        } finally {
          // Dismiss Loading
          stopLoader();
        }
      };

      img.src = objectUrl;
    } catch (error: any) {
      // Show Message
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    }
  };



  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      className="pb-20"
    >
      <Box
        width={{
          xs: '90%',
          md: '50%',
        }}
        maxWidth={600}
      >
        <Typography
          component='p'
          color='#4A403A'
          fontSize='26px'
          textAlign={'left'}
          letterSpacing='0.01px'
          sx={{ marginTop: { xs: 2.5, md: 5 }, marginBottom: { xs: 2.5, md: 5 } }}
        >
          {staticTexts.editProfile}
        </Typography>

        <Box
          bgcolor='#FFF7E6'
          borderRadius='8px'
        >
          <Box
            sx={{
              maxWidth: 600,
              margin: 'auto',
              borderRadius: 2,
              overflow: 'hidden',
              backgroundColor: '#fff8e1',
            }}
          >
            {/* Editable Cover Photo */}
            <Box
              sx={{
                height: 150,
                background:
                  coverProfileImageUrl || coverPhoto
                    ? `url(${coverProfileImageUrl || coverPhoto}) no-repeat center/cover`
                    : 'linear-gradient(to bottom, #FFE0B5, #FAB98A)',
                position: 'relative',
              }}
            >
              {/* Edit Cover Photo Icon */}
              {isEdit && (
                 <Box
                 aria-label='edit cover photo'
                 sx={{
                   position: 'absolute',
                   bottom: 16,
                   right: 16,
                   backgroundColor: '#FFF7E6',
                   padding: '8px',
                   borderRadius: '8px',
                   fontSize: '14px',
                   color: '#776E65',
                   cursor: 'pointer',
                   '&:hover': {
                     backgroundColor: '#f5f5f5',
                   },
                 }}
                 zIndex={2}
                 onClick={handleClickCoverPhotoButton}
               >
                 <input
                   ref={fileInputRef}
                   hidden
                   accept='image/*'
                   type='file'
                   onChange={handleCoverPhotoChange}
                   disabled={!isEdit}
                 />
                 {staticTexts.editCoverPhoto}
                 <PhotoCameraIcon sx={{ fontSize: '14px', marginInlineStart:'8px', color: '#776E65' }} />
               </Box>
              )}
            </Box>

            {/* Editable Profile Picture */}
            <Box
              sx={{
                position: 'relative',
                top: -70,
                display: 'flex',
                flexDirection: "column",
                alignItems: "center",
                justifyContent: 'center',
              }}
            >
              <Box className='w-full text-center mb-1'>{staticTexts.profilePicture}</Box>
              <UploadBox
                defaultImage={profileImageUrl || profilePhoto || defaultProfileImg}
                onFileChange={handleProfilePhotoChange}
                disabled={!isEdit}
              />
              <Box className='w-1/2 text-[#776E65] text-sm mt-2 text-center mb-1'>* PNG, JPG & HEIF formats, up to 5MB.</Box>
            </Box>

            {/* Profile Details */}
            <Box sx={{ p: 3, pt: 0 }}>
              <form onSubmit={handleSubmit(onSubmit)}>

                {/* Business Name Input Field */}
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: staticTexts.organizationNameIsRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      label={staticTexts.organizationName}
                      variant='outlined'
                      margin='normal'
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      disabled={!isEdit}
                    />
                  )}
                />

                {/* Email Input Field */}
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: staticTexts.organizationEmailIsRequired,
                    pattern: {
                      value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, // TODO - Mayank: Put this regex in common file may be in shared or some other place
                      message: staticTexts.invalidEmailAddress,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      label={staticTexts.emailAddress}
                      variant='outlined'
                      margin='normal'
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      disabled={true}
                    />
                  )}
                />

                {/* Business Address Input Field */}
                <Controller
                  name='address'
                  control={control}
                  rules={{
                    required: staticTexts.organizationAddressIsRequired,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      label={staticTexts.organizationAddress}
                      variant='outlined'
                      margin='normal'
                      error={!!errors.address}
                      helperText={errors.address?.message}
                      disabled={!isEdit}
                    />
                  )}
                />

                {/* Preferred Language Input Field */}
                <Controller
                  name='preferredLanguage'
                  control={control}
                  rules={{ required: staticTexts.preferredLanguageIsRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      select
                      label={staticTexts.preferredLanguage}
                      variant='outlined'
                      margin='normal'
                      error={!!errors.preferredLanguage}
                      helperText={errors.preferredLanguage?.message}
                      disabled={!isEdit}
                    >
                      <MenuItem value='en'>{staticTexts.english}</MenuItem>
                      <MenuItem value='ar'>{staticTexts.arabic}</MenuItem>
                    </TextField>
                  )}
                />

                {/* Description Business Input Field */}
                <Controller
                  name='description'
                  control={control}
                  rules={{
                    required: staticTexts.aboutOrganizationIsRequired,
                  }}
                  render={({ field }) => (
                    <TextField
                      multiline
                      rows={4}
                      {...field}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      label={staticTexts.aboutOrganization}
                      variant='outlined'
                      margin='normal'
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      disabled={!isEdit}
                    />
                  )}
                />

                <Box className="flex justify-between mt-10">

                  {/* Cancel Button */}
                  {isEdit && (<CustomButton buttonStyle={{ paddingX: 0, flexGrow: 0, marginLeft: -1.2 }} variant={AppButtonVariant.TEXT} title={staticTexts.cancel} onPress={onCancelClick} />)}

                  <Box className="flex justify-end w-full">
                    <CustomButton title={isEdit ? staticTexts.saveChanges : staticTexts.editProfile} onPress={onSubmitClick} />
                  </Box>
                </Box>

              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
