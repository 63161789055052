import moment from 'moment';

class DateTimeFormatting {
  /**
   * Time Values Constants
   * */
  static TimeValues = {
    SECOND_MILLIS: 1000,
    MINUTE_MILLIS: 60 * 1000,
    HOUR_MILLIS: 60 * 60 * 1000,
    DAY_MILLIS: 24 * 60 * 60 * 1000,
    YEARS: 365,
    DAYS: 31,
    WEEK: 7,
  };
  /**
   * User Availability Keys
   *
   * Note: These Keys are dependent of Configuration defined by Backend in APIs
   * Don't Change it without permission
   * */
  static DateTimeFormat = {
    MONOGO_DB_UTC: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    hh_mm_a: 'hh:mm A',
    MMM_DD: 'MMM DD',
    YYYY: 'YYYY',
    MMM: 'MMM',
    MMM_DD_YYYY: 'MMM DD, YYYY',
    DD_MMM_yyyy: 'DD MMM yyyy',
    DD_MM_YYYY: 'DD/MM/YYYY',
    MM_YYYY: 'MM/YYYY',
    MMM_dd_yyyy_hh_mm_aa: 'MMMM DD, yyyy hh:mm A',
    YYYY_MM_DD: 'YYYY-MM-DD',
    MMM_d_th_yyyy_hh_mm_a: 'MMM DD, yyyy hh:mm A',
    DD_MM: 'DD/MM',
    MMM_Do_YYYY_hh_mm_A: 'MMM Do, YYYY hh:mm A',
    DD_MM_YYYY_hh_mm_A: 'DD/MM/YYYY hh:mm A',
    YYYY_MM_DD_hh_mm_A: 'YYYY-MM-DD-hh-mm-A',
  };

  /**
   * Time Ago Keys
   *
   * Note: These Keys are dependent of Configuration to pass timestamp in APIs
   * Don't Change it without permission
   * */
  static TIME_AGO_DDURATION = {
    MONTHS: 'months',
    WEEKS: 'weeks',
    DAYS: 'days',
  };

  static timeAgo(timestamp: Date): string {
    const now: Date = new Date();
    const seconds: number = Math.floor(
      (now.getTime() - timestamp.getTime()) / 1000,
    );

    const interval: number = Math.floor(seconds / 60);

    if (interval < 1) {
      return 'just now';
    } else if (interval < 60) {
      return `${interval} ${interval === 1 ? 'min' : 'mins'} ago`;
    } else if (interval < 24 * 60) {
      const hours: number = Math.floor(interval / 60);
      return `${hours} ${hours === 1 ? 'hr' : 'hrs'} ago`;
    } else if (interval < 48 * 60) {
      return 'yesterday';
    } else if (now.getFullYear() === timestamp.getFullYear()) {
      // Same year, display month and date
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };
      return timestamp.toLocaleDateString(undefined, options);
    } else {
      // Previous year, display month, date, and year
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return timestamp.toLocaleDateString(undefined, options);
    }
  }

  /**
   * @return Return string by replacing first letter to capital case
   *
   * @param str String
   * */
  static shortDateTime(str: string): string {
    if (str.trim().length === 0) {
      return str;
    } // Handle empty string

    const currentTime = moment()
      .local()
      .format(DateTimeFormatting.DateTimeFormat.MMM_DD_YYYY);
    const currentYear = moment()
      .local()
      .format(DateTimeFormatting.DateTimeFormat.YYYY);

    const messageDateTime = moment
      .utc(str)
      .local()
      .format(DateTimeFormatting.DateTimeFormat.MMM_DD_YYYY);
    const messageYear = moment
      .utc(str)
      .local()
      .format(DateTimeFormatting.DateTimeFormat.YYYY);
    if (currentTime === messageDateTime) {
      return moment
        .utc(str)
        .local()
        .format(DateTimeFormatting.DateTimeFormat.hh_mm_a);
    } else if (currentYear !== messageYear) {
      return messageDateTime;
    } else {
      return moment
        .utc(str)
        .local()
        .format(DateTimeFormatting.DateTimeFormat.MMM_DD);
    }
  }

  /**
   * Convert TimeStamp to any format
   *
   * @param inputData Which you want to convert
   * @param isOldInUTC if old Time in UTC
   * @param mOldFormat Format of the inputData
   * @param shouldNewInUTC if want to convert output in UTC
   * @param mNewFormat Output data Format
   *
   * */
  static getDateByFormatCustomUTC(
    inputData: string,
    isOldInUTC: boolean,
    mNewFormat: string,
    mOldFormat?: string,
    shouldNewInUTC?: boolean,
  ): string {
    if (!inputData || !inputData.length) {
      return inputData;
    } // Handle empty string

    if (isOldInUTC) {
      //It will return new data in local timezone
      return moment.utc(inputData).local().format(mNewFormat);
    } else {
      //It will return new data in utc timezone
      return moment.utc(inputData).format(mNewFormat);
    }
  }

  /**
   * @return TimeStamp from any format
   *
   * @param inputData Which you want to convert
   * @param isOldInUTC if old Time in UTC
   * @param shouldNewInUTC if want to convert output in UTC
   *
   * */
  static getTimeStampFromTimeFormat(
    inputData: string,
    isOldInUTC: boolean,
    shouldNewInUTC: boolean = false,
  ): number {
    // Handle empty string
    if (!inputData || !inputData.length) {
      return 0;
    }

    let momentData;
    if (isOldInUTC) {
      momentData = moment.utc(inputData);
    } else {
      momentData = moment(inputData);
    }

    if (!shouldNewInUTC) {
      momentData = momentData.local();
    }

    return momentData.valueOf();
  }

  /**
   *
   * @returns Current Time in UTC format
   */
  static getCurrentTimeInUTC(): string {
    const currentTimeMillis = new Date().getTime();
    return new Date(currentTimeMillis).toUTCString();
  }

  /**
   * Convert LONG time into FORMAT Date/Time String
   *
   * @param timestamp TimeStamp in LONG
   * @param shouldNewInUTC TRUE if want to convert result into UTC converted
   * @param newFormat New Date/Time Format
   * */
  static getDateFormatFromLong(
    timestamp: number,
    shouldNewInUTC: boolean,
    newFormat: string,
  ): string {
    let momentData;
    if (shouldNewInUTC) {
      momentData = moment.utc(timestamp);
    } else {
      momentData = moment(timestamp);
    }
    return momentData.format(newFormat);
  }

  static dateStringToUnixMilliseconds = (dateString: string): number => {
    const unixMilliseconds = moment(dateString).valueOf();
    return unixMilliseconds;
  };

  /**
   *
   * @returns Current Time in Seconds
   */
  static getCurrentTimeInUnixSeconds = () => {
    const currentDate = new Date();
    return Math.floor(currentDate.getTime() / 1000);
  };

  /**
   *
   * @param duration Accept String Value
   * @returns Time Ago in Unix Seconds.
   */
  static getTimeAgoInUnixSeconds = (duration: string) => {
    const newDate = new Date();
    let date;

    switch (duration) {
      case this.TIME_AGO_DDURATION.MONTHS:
        date = new Date(
          newDate.getFullYear(),
          newDate.getMonth() - 1,
          newDate.getDate(),
        );
        break;
      case this.TIME_AGO_DDURATION.WEEKS:
        date = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate() - 7,
        );
        break;
      case this.TIME_AGO_DDURATION.DAYS:
        date = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate() - 1,
        );
        break;
      default:
        throw new Error('Invalid duration to format');
    }
    return Math.floor(date.getTime() / 1000);
  };

  /**
   * @param duration Accepts a Date Value in the format 2023-07-02T00:00:00.000Z
   * @returns Time in Unix Seconds.
   */
  static getDateToUnixSeconds = (date: Date) => {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
    return Math.floor(newDate.getTime() / 1000);
  };

  /**
   * @param date Accepts a Date Value in the format 2023-07-02T00:00:00.000Z
   * @returns Date String in the desired format.
   */
  static getDateFormatFromDate(date: Date, newFormat: string): string {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
    const unixTimeStampInSeconds = newDate.getTime();
    const formattedDate = this.getDateFormatFromLong(
      unixTimeStampInSeconds,
      false,
      newFormat,
    );
    return formattedDate;
  }
}

export default DateTimeFormatting;
