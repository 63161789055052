import React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled, useMediaQuery } from '@mui/system';

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  padding-bottom: 0;

  .MuiPagination-ul {
    display: flex;
    flex-wrap: nowrap; 
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .MuiPagination-ul::-webkit-scrollbar {
    display: none; 
  }

  .MuiPaginationItem-root {
    font-size: 0.9rem;
    color: #6b7280;
    border: none;
    background: none;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    margin: 0px 8px;
    flex-shrink: 0;
  }

  .MuiPaginationItem-root:hover {
    background-color: #ffe0b5;
  }

  .MuiPaginationItem-ellipsis {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #776e65;
    font-weight: 800;
    margin-top: auto;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #ffe0b5;
    font-weight: bold;
  }

  .MuiPaginationItem-page.Mui-disabled {
    color: #e5e7eb;
  }

  .MuiPaginationItem-previousNext {
    background-color: #ffe0b5;
    color: #fbbf24;
    border-radius: 6px;
    width: 36px;
    height: 36px;
  }

  @media (max-width: 600px) {
    padding: 1rem 0;
    .MuiPaginationItem-root {
      margin: 0px 4px;
      font-size: 0.8rem;
    }

    .MuiPaginationItem-previousNext.Mui-disabled {
      background-color: #ede6db;
      color: #e5e7eb;
    }
  }
`;


type CustomPaginationProps = {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const CustomPagination: React.FC<CustomPaginationProps> = ({ count, page, onChange }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <StyledPagination
      count={count}
      page={page}
      onChange={onChange}
      siblingCount={isSmallScreen ? 0 : 1}
      boundaryCount={1}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          components={{
            previous: () => <ArrowBackIosIcon sx={{ color: '#776E65', fontSize: '14px' }} />,
            next: () => <ArrowForwardIosIcon sx={{ color: '#776E65', fontSize: '14px' }} />,
          }}
        />
      )}
    />
  );
};

export default CustomPagination;
