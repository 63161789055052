import React from 'react';
import { Button as MuiButton, ButtonProps, SxProps } from '@mui/material';
import { AppButtonVariant } from '../../../constants/AppConstants';

/**
 * Constants for default colors
 */
const DEFAULT_COLORS = {
  text: 'text-FF6F61',
  containedBackground: 'bg-FF6F61',
  containedText: 'text-FFF7E6',
};

export interface CustomButtonProps extends ButtonProps {
  onPress?: () => void; // Function to handle button click
  title: string; // The text to be displayed on the button
  buttonStyle?: SxProps; // Custom button styles
  textColor?: string; // Custom text color
  backgroundColor?: string; // Custom background color for contained button
  outlineColor?: string; // Custom outline color for outlined button
  height?: string | number; // Custom height for the button
  width?: string | number; // Custom width for the button
}

/**
 * A reusable button component with Text, Outlined, and Contained variants.
 *
 * @author Harshit Kumar
 * @since Dec 24, 2024
 */
export const CustomButton: React.FC<CustomButtonProps> = ({
  variant = AppButtonVariant.CONTAINED,
  onPress,
  title,
  buttonStyle,
  textColor,
  backgroundColor,
  height,
  width,
  ...props
}) => {
  // Determine styles based on variant
  let computedStyles: SxProps = {
    height,
    width,
  };

  // Apply styles based on the variant
  switch (variant) {
    case AppButtonVariant.TEXT:
      computedStyles = {
        color: textColor ?? DEFAULT_COLORS.text,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(255, 111, 97, 0.1)',
        },
        height: height ?? '32px',
        width: width ?? 'fit-content',
        paddingX: '10px',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: 'initial',
        fontWeight: 'medium'
      };
      break;

    case AppButtonVariant.OUTLINED:
      computedStyles = {
        color: textColor ?? DEFAULT_COLORS.text,
        border: `1px solid ${textColor ?? DEFAULT_COLORS.text}`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(255, 111, 97, 0.1)',
        },
        height: height || '32px',
        width: width || 'fit-content',
        paddingX: '10px',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: 'initial',
        fontWeight: 'medium'
      };
      break;

    case AppButtonVariant.CONTAINED:
      computedStyles = {
        color: textColor ?? DEFAULT_COLORS.containedText,
        backgroundColor: backgroundColor ?? DEFAULT_COLORS.containedBackground,
        '&:hover': {
          backgroundColor: backgroundColor ? `${backgroundColor}CC` : '#FF5C4F',
        },
        height: height ?? '32px',
        width: width ?? 'fit-content',
        paddingX: '10px',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: 'initial',
        fontWeight: 'medium'
      };
      break;

    default:
      break;
  }

  return (
    <MuiButton
      variant={variant}
      onClick={onPress}
      sx={{ ...computedStyles, ...buttonStyle }}
      {...props}
    >
      {title}
    </MuiButton>
  );
};

export default CustomButton;
