import { Avatar, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ImageCarousel from '../../components/Layouts/ImageCarousel';
import { getStaticTexts, productPlaceHolder, ProfileIcon } from '../../assets';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import { Button } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClaimInstruction } from './claim-instruction';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getItemById } from '../../services/home';
import { clipText, formatDate, transformImages } from '../../utils';
import { textTransform } from '../../services/common';
import { checkClaimedItem } from '../../services/aggregation';
import { set } from 'react-hook-form';
import { LocalStorageKeys, UserType } from '../../constants/AppConstants';

// TODO - Mayank: Add comments on functions
// TODO - Mayank: Remove unused imports
// TODO - Mayank: Use translations

export const ItemDetail = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const [itemDetail, setItemDetails] = useState<any>({});
  const [isClaimed, setIsClaimed] = useState(false);
  const staticTexts = getStaticTexts(language);
  const { pathname } = useLocation();

  const isBusinessUser =
    (
      localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE) || localStorage.getItem(LocalStorageKeys.USER_TYPE)
    )?.toUpperCase() === UserType.BUSINESS;

  useEffect(() => {
    const itemId = pathname.split('/').pop();
    const fetchItemDetail = async () => {
      try {
        const response = await getItemById(String(itemId));
        setItemDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    const checkIfItemIsAlreadyClaimed = async () => {
      try {
        const response = (await checkClaimedItem(String(itemId))) as any;
        setIsClaimed(!!response);
      } catch (error) {
        console.log(error);
      }
    };
    checkIfItemIsAlreadyClaimed();
    fetchItemDetail();
  }, []);

  return (
    itemDetail && (
      <Box
        className='md:mt-24 mt-4 mx-auto'
        sx={{
          width: {
            xs: '90%',
            md: '75%',
          },
        }}
      >
        <div className='flex items-center mb-6 text-accent'>
          <button
            className='text-coral-500 flex items-center'
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon className='w-5 h-5 mr-2' />
            <span>{staticTexts.back}</span>
          </button>
        </div>
        <Box
          sx={{
            display: 'grid',
            gap: '12px',
            gridTemplateColumns: { xs: '1fr', md: '3fr 2fr' },
          }}
          marginBottom='32px'
        >
          {/* Box 1 */}
          <Box width={{ xs: '100%', md: '100%' }}>
            <Box
              sx={{
                marginBottom: '12px',
                width: '100%',
                margin: '0 auto',
              }}
            >
              <ImageCarousel images={transformImages(itemDetail?.images)} />
            </Box>
            <ClaimInstruction />
          </Box>

          {/* Box 2 */}
          <Box marginBottom='32px'>
            <Box
              className='p-6 bg-[#FFF7E6] rounded-lg'
              sx={{
                order: { xs: 2, md: 1 },
              }}
            >
              <Typography
                className='text-secondary-200'
                fontSize='12px'
                component='p'
              >
                {textTransform(itemDetail?._category?.name)}
              </Typography>

              <Typography
                component='p'
                className='text-secondary-400'
                fontSize='18px'
                fontWeight={500}
                variant='body1'
              >
                {textTransform(itemDetail?.name)}
              </Typography>

              <Typography
                className='text-secondary-200'
                fontSize='14px'
                component='p'
                marginTop='12px'
                variant='body1'
              >
                {textTransform(itemDetail?.description)}
              </Typography>

              <Box
                className='flex'
                marginTop='24px'
              >
                <LocationOnIcon
                  className='me-1'
                  sx={{ color: '#776E65', fontSize: '18px' }}
                />
                <Typography
                  className='text-secondary-200'
                  component='p'
                  fontSize='14px'
                >
                  {/* TODO - Mayank: Use translations */}
                  Last seen near <span>{textTransform(itemDetail?.location?.name)}</span>
                </Typography>
              </Box>

              {isBusinessUser && (
                <Box
                  className='flex'
                  marginTop='8px'
                >
                  <CorporateFareRoundedIcon
                    className='me-1'
                    sx={{ color: '#776E65', fontSize: '18px' }}
                  />
                  <Typography
                    className='text-secondary-200'
                    component='p'
                    fontSize='14px'
                  >
                    Found by{' '}
                    <span
                      className='underline text-accent cursor-pointer'
                      onClick={() => navigate('/organization-page')}
                    >
                      Vertex Solution
                    </span>
                  </Typography>
                </Box>
              )}

              <Box
                className='flex'
                marginTop='8px'
              >
                <AccessTimeFilledIcon
                  className='me-1'
                  sx={{ color: '#776E65', fontSize: '18px' }}
                />
                <Typography
                  className='text-secondary-200'
                  component='p'
                  fontSize='14px'
                >
                 {staticTexts.foundOn} {formatDate(itemDetail?.eventDate)}
                </Typography>
              </Box>

              <Box
                className='flex'
                marginTop='8px'
              >
                <PublicRoundedIcon
                  className='me-1'
                  sx={{ color: '#776E65', fontSize: '18px' }}
                />
                <Typography
                  className='text-secondary-200'
                  component='p'
                  fontSize='14px'
                >
                  {itemDetail?.visibleToEveryone ? staticTexts.public : staticTexts.private}
                </Typography>
              </Box>

              <Button
                buttonStyle={{
                  background: '#FF6F61',
                  textAlign: 'center',
                  marginTop: '24px',
                }}
                disabled={isClaimed}
                onClick={() =>
                  navigate(`/claim-item/${itemDetail?._category?._id}/${itemDetail?._id}`, {
                    state: {
                      name: itemDetail?.name,
                      description: itemDetail?.description,
                      location: itemDetail?.location,
                      image: itemDetail?.images[0] || productPlaceHolder,
                    },
                  })
                }
              >
                {isClaimed ? staticTexts.claimed : staticTexts.claimItem}
              </Button>

              <Box
                className='flex'
                bgcolor='#FFE0B5'
                borderRadius='8px'
                paddingY='8px'
                marginTop='12px'
              >
                <DirectionsWalkRoundedIcon className='me-1 text-secondary-100' />
                <Typography
                  className='text-secondary-200'
                  component='p'
                  fontSize='12px'
                >
                  {staticTexts.claimItemNote}
                </Typography>
              </Box>

              <Typography
                className='text-secondary-200'
                component='p'
                marginTop='12px'
                fontSize='12px'
              >
                {staticTexts.ownershipConfirmationNote}
              </Typography>

              <Box marginTop='24px'>
                <Typography variant='h6'>{staticTexts.disclaimer}</Typography>
                <Typography
                  className='text-secondary-200'
                  component='p'
                  marginTop='8px'
                  fontSize='12px'
                >
                  <span className='text-error'>*</span>
                  {staticTexts.claimDisclaimer}
                </Typography>
              </Box>
            </Box>
            {itemDetail?._user?.type === 'BUSINESS' && (
              <Box
                marginTop='12px'
                className='bg-[#FFF7E6] rounded-lg flex justify-start w-full p-6'
              >
                <Avatar
                  className='me-2'
                  alt='img'
                  src={itemDetail?._user?.profile}
                />
                <Box>
                  <Typography
                    className='font-bold'
                    component='p'
                    fontSize='16px'
                  >
                   {itemDetail?._user?.name}
                  </Typography>
                  <Typography
                    fontSize='12px'
                    component='p'
                  >
                    {staticTexts.founderFrom} {itemDetail?._user?.address}
                  </Typography>
                  <Button
                    disabled={!isClaimed}
                    buttonStyle={{
                      fontSize: '14px',
                      marginTop: '14px',
                      color: '#FFF7E6',
                      background: '#FF6F61',
                    }}
                    onClick={() =>
                      navigate('/chat', {
                        state: {
                          isFirstTime: true,
                          _receiver: itemDetail?._user?._id,
                          _item: itemDetail._id,
                          username: itemDetail?._user?.name,
                          userProfile: itemDetail?._user?.profile,
                        },
                      })
                    }
                  >
                    {staticTexts.chatWithFounder}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
};
